import { IUserPermissionsRequestModel } from '../../models/requests/UserPermissionsRequestModel'
import { IUserClaimsRequestModel, IUserClaimRequestModel } from '../../models/requests/UserClaimsRequestModel'
import { IUserRequestModel } from '../../models/requests/UserRequestModel'
import { IUserRolesRequestModel } from '../../models/requests/UserRolesRequestModel'
import { DictionaryModel } from '../../models/responses/DictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IUserModel } from '../../models/responses/UserModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'

import API from './Api'

class UserApi {
  async GetUser(id: string) {
    return await API.get<IUserModel>('i/CompanyManager/Users/' + id)
  }

  async AddUser(model: IUserRequestModel) {
    return await API.post('i/CompanyManager/Users', model)
  }

  async ImportUser(model: FormData) {
    return await API.post('i/CompanyManager/Users/Import',
      model, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }
  async UpdateUserClaims(model: IUserClaimsRequestModel) {
    return await API.put('i/CompanyManager/Users/claims/' + model.id, model)
  }


  async GetUsersByFilter(filter: IPaginationFilter, filters: DictionaryModel[]) {
    return await API.post<PagedResponseModel<IUserModel[]>>(`i/CompanyManager/Users/filters${generateQueryParams(filter)}`, filters)
  }

  async GetUserDictionaryFilter(filters: DictionaryModel[]) {
    return await API.post('i/CompanyManager/Users/dictionary/filters', filters)
  }

  async GetUserDictionaryIds(ids: string[]) {
    return await API.post('i/CompanyManager/Users/dictionary/ids', ids)
  }
}

export default new UserApi()
