import { FC, useCallback, useEffect, useState } from 'react'
import ExamApi from '../../../../infrastructure/api/ExamApi'
import { IExamModel } from '../../../../models/responses/ExamModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { IExamTypeModel } from '../../../../models/responses/ExamTypeModel'
import { ILevelModel } from '../../../../models/responses/LevelModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import ExamTypeApi from '../../../../infrastructure/api/ExamTypeApi'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const ExamListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [exams, setExams] = useState<PagedResponseModel<IExamModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<Map<string, string>>(new Map())
  const [sort, setSort] = useState('title_asc')
  const [levels, setLevels] = useState<ILevelModel[]>([])
  const [examTypes, setExamTypes] = useState<IExamTypeModel[]>()

  const init = async () => {
    var lastList = filters;
    lastList.set("CompanyId", user.companyId!)

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: lastList
    }
    var response = await ExamApi.GetExams(paginationFilter)
    setExams(response.data)

    var responseLevels = await LevelApi.GetLevelDictionary()
    setLevels(responseLevels.data)

    var responseExamTypes = await ExamTypeApi.GetExamTypeDictionary()
    setExamTypes(responseExamTypes.data.map(d => ({
      id: d.id.toString(),
      title: d.title
    })))
  };

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Exams'
        data={exams?.items}
        currentTotalRecords={exams?.totalRecords!!}
        currentPageNumber={exams?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: 'title',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Exam Type',
            name: 'examTypeId',
            options: {
              filter: true,
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{examTypes?.find(i => i.id == value)?.title}</div>
              },
            },
          },
          {
            label: 'Level',
            name: 'levelId',
            options: {
              filter: true,
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{levels.find(i => i.id == value)?.name}</div>
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={exams?.items[dataIndex]?.id!!}
                    itemParam={exams?.items[dataIndex]?.title!!}
                    links={[
                      { type: 'edit', value: '/exam/exams/edit/' + exams?.items[dataIndex]?.id!! }
                    ]}
                    types={[TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { ExamListModal }
