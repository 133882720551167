import { IScheduleStatisticsModel } from '../../models/responses/ScheduleStatisticsModel'
import API from './Api'

class DashboardApi {
  async GetStatistics(companyId: string) {
    return await API.get<IScheduleStatisticsModel>(`s/CompanyManager/Dashboards/statistics/${companyId}`)
  }

}

export default new DashboardApi()
