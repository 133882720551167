export const customSelectStyles = (isSolid: boolean = false) => ({
    control: (styles: any) => ({
        ...styles,
        "background": (isSolid ? '#F5F8FA' : '#FFF'),
        "padding": "5px 6px",
        "border": "0"
    }),
    menuPortal: (styles: any) => ({
        ...styles,
        zIndex: 9999
    }),});
