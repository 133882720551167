/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

type Props = {
    type: string,
    size: number,
    series: number[],
    labels: string[],
    text: string
}

const PieDonutChart: React.FC<Props> = ({ type = 'donut', size = 380, series = [], labels = [], text = '%' }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        var options = getChartOptions(height, series, labels, text);
        const chart = new ApexCharts(chartRef.current, options)
        if (chart) {
            chart.render()
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, series])

    return (
        <div ref={chartRef} style={{ height: `${size}px` }}></div>
    )
}

export { PieDonutChart }

function getChartOptions(height: number, _series: number[], _labels: string[], _text: string): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = getCSSVariableValue('--bs-light-info')

    const wrong = getCSSVariableValue('--bs-danger')
    const success = getCSSVariableValue('--bs-success')

    return {
        theme: {
            "mode": "light",
        },
        title: {
            text: "Success", align: 'center',
            margin: 10,
            offsetX: -45,
            offsetY: 200,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: '',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        subtitle: {
            text: _text, align: 'center',
            margin: 10,
            offsetX: -45,
            offsetY: 215,
            floating: false,
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        series: _series,
        dataLabels: { enabled: true },
        chart: {
            width: 500,
            type: 'donut',
        },
        labels: _labels,
        colors: [success, wrong, labelColor],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                },
            }
        }]
    }

}
