import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { UserImportFormValidations } from '../../../validations/UserFormValidations'
import { useFormik } from 'formik'
import { IUserImportRequestModel } from '../../../../models/requests/UserRequestModel'
import { RoleModel } from '../../../../models/responses/RoleModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select';
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const UserImportModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [roles, setRoles] = useState([] as RoleModel[])
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [errors, setErrors] = useState([])
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)
  const [jobInterviews, setJobInterviews] = useState<SelectModel[]>([])
  const [selectedJobInterview, setSelectedJobInterview] = useState<SelectModel>(selectInitValues)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [loadFile, setLoadFile] = useState<any>(undefined)

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const formik = useFormik({
    initialValues: { companyId: undefined, jobInterviewId: undefined },
    enableReinitialize: true,
    validationSchema: UserImportFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        let model: IUserImportRequestModel = {
          roles: roles.filter(i => i.name == "Student").map(i => i.name),
          companyId: user.companyId,
          claims: []
        }
        var permissions: string[] = [];
        roles.filter(i => i.name == "Student").map(role => {
          permissions.push(...role.permissions!!)
        })

        model.claims = permissions.filter(onlyUnique).map(item => {
          var object: DictionaryModel = { type: "Permission", value: item };
          return object
        });

        if (selectedCompany.value != "") model.claims.push({ type: "CompanyId", value: selectedCompany.value })
        if (selectedJobInterview.value != "") model.claims.push({ type: "JobInterviewId", value: selectedJobInterview.value })

        processUser(model);
      }, 500)
    },
  })

  async function processUser(request: IUserImportRequestModel) {
    try {
      setLoading(true)

      let model = new FormData()
      model.append('file', loadFile)
      model.append('claims', JSON.stringify(request.claims))
      model.append('roles', JSON.stringify(request.roles))

      var result = await UserApi.ImportUser(model)
      if (result.data.hasError) {
        setErrors(result.data.errors)
      } else {
        setErrors([])
        formik.resetForm()
        handleClose()
      }
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }


  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  const handleChangeJobInterview = (e: any) => {
    setSelectedJobInterview(e);
  }

  const handleChangeFile = (event: any) => {
    setLoadFile(event.target.files[0])
  }

  const init = async () => {
    var response = await RoleApi.GetRoles()
    response.data.items.forEach(item => {
      item.isActive = false;
    })
    setRoles(response.data.items)

    if (user.companyId) {
      var companies = await CompanyApi.GetSubCompaniesDictionary(user.companyId)
      var dataParentCompanies: SelectModel[] = [
        ...companies.data.map((d) => ({
          value: d.id.toString(),
          label: d.name,
        })),
      ]
      setCompanies(dataParentCompanies)
    }
  }

  useEffect(() => {
    init();
  }, [show]);

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-750px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='fw-bolder'>Import Students</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body  mx-5 my-7'>
            <div className='row'>
              <div className='col-12'>
                {
                  errors.length > 0 && <div className='alert alert-danger'>  <h4 className="alert-heading">Please fix the following issues and try again!</h4>
                    <p>
                      <ol>
                        {errors.map((item, key) => {
                          return (
                            <li key={key}>{item}</li>
                          )
                        })}
                      </ol>
                    </p>
                  </div>
                }
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 mb-6'>
                    <label className='form-label fw-bolder required'>Company</label>
                    <Select
                      {...formik.getFieldProps('companyId')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => handleChangeCompany(event)}
                      value={selectedCompany}
                      options={companies}
                    />
                    <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
                  </div>
      
                  <div className='mb-6'>
                    <label className='form-label fw-bolder required'>File</label>
                    <input
                      {...formik.getFieldProps('file')}
                      type="file"
                      value={undefined}
                      className='form-control form-control-solid'
                      onChange={(event) => handleChangeFile(event)} accept=".xlsx"
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form >
    </Modal >
  )
}

export { UserImportModal }