import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SelectModel } from '../../../models/SelectModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetUnitDictionary: '[Set Unit Dictionary] Action'
}

export interface IUnitState {
    unitDictionary?: SelectModel[],
}

const initialUnitState: IUnitState = {
    unitDictionary: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-units',
        whitelist: ['unitDictionary'],
        blacklist: []
    },
    (state: IUnitState = initialUnitState, action: ActionWithPayload<IUnitState>) => {
        switch (action.type) {
            case actionTypes.SetUnitDictionary: {
                const unitDictionary = action.payload?.unitDictionary
                return { ...state, unitDictionary }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setUnitDictionary: (unitDictionary: Array<SelectModel>) => ({ type: actionTypes.SetUnitDictionary, payload: { unitDictionary } }),
}
