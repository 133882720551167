import { ICompanyRequestModel } from '../../models/requests/CompanyRequestModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'
import { ICompanyModel } from '../../models/responses/CompanyModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import API from './Api'

class CompanyApi {

  async GetCompanies(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ICompanyModel[]>>(`e/CompanyManager/Companies${generateQueryParams(filter)}`)
  }

  async GetCompanyDictionary() {
    return await API.get<ICompanyModel[]>(`e/CompanyManager/Companies/dictionary`)
  }

  async GetSubCompaniesDictionary(companyId: string) {
    return await API.get<ICompanyModel[]>(`e/CompanyManager/Companies/sub/${companyId}`)
  }

  async GetCompany(id: string) {
    return await API.get<ICompanyModel>('e/CompanyManager/Companies/' + id)
  }

  async AddCompany(model: ICompanyRequestModel) {
    return await API.post('e/CompanyManager/Companies', model)
  }

  async UpdateCompany(model: ICompanyRequestModel) {
    return await API.put('e/CompanyManager/Companies/' + model.id, model)
  }

  async DeleteCompany(itemId: string) {
    return await API.delete<boolean>('e/CompanyManager/Companies/' + itemId)
  }

}

export default new CompanyApi()
