import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'


import * as auth from '../../app/modules/auth'
import * as account from '../../app/modules/accounts'
import * as level from '../../app/pages/levels/redux/LevelRedux'
import * as exam from '../../app/pages/exams'
import * as unit from '../../app/pages/units/redux/UnitRedux'


export const rootReducer = combineReducers({
  auth: auth.reducer,
  account: account.reducer,
  level: level.reducer,
  exam: exam.reducer,
  unit: unit.reducer})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
