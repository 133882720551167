/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { ITopicListItemModel } from '../../../../models/responses/TopicListModel'
import { TopicStatusEnum } from '../../../../enums/TopicStatusEnum'
import { UserModel } from '../../auth/models/UserModel'

export function Topics() {
    const authUser: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
    const levelId: number = parseInt(user.details?.find(i => i.type == "LevelId")?.value!!);
    const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
    const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
    const [topics, setTopics] = useState<ITopicListItemModel[]>([])

    const init = useCallback(async () => {
        var responseLevels = await LevelApi.GetLevelDictionary()
        var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
            value: d.id.toString(),
            label: d.name + (levelId == d.id ? ' (Present Level)' : '')
        }))]
        setLevelDictionary(dataLevels)

        handleChangeLevel(dataLevels.find(i => i.value == levelId.toString()))
    }, [levelId]);

    useEffect(() => {
        // if (!user.roles?.some(s => s == RoleEnum.Student)) {
        //    document.location.href = "/error/404"
        // } else {
        init()
        // }
    }, [user])

    const getTopicLists = async (levelId: number) => {
        if (levelId > 0) {
            var responseTopicList = await TopicApi.GetStudentTopicLists(authUser.companyId!, user.id!, levelId);
            if (responseTopicList.data.topics)
                setTopics(responseTopicList.data.topics)
            else setTopics([])
        } else setTopics([])
    }

    const handleChangeLevel = (e: any) => {
        setSelectedLevel(e)
        getTopicLists(parseInt(e?.value))
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Available Topics</h3>
                            </div>
                            <div className='mt-4 col-5'>
                                <Select
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLevel(event)}
                                    value={selectedLevel}
                                    options={levelDictionary}
                                /></div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                <div className='row'>
                                    {topics.filter(i => i.topicStatus == TopicStatusEnum.Active).map((topic, index) => {
                                        return (
                                            <div className='col-6' key={index}>
                                                <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-3">
                                                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                                        <span className="badge badge-circle badge-dark">{index + 1}</span>
                                                    </span>
                                                    <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                                                        <h5 className="mb-1">{topic.topicTitle}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Completed Topics</h3>
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {topics.filter(i => i.topicStatus == TopicStatusEnum.Completed).map((topic, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-success pe-0 pe-sm-10">
                                                <h5 className="mb-1">{topic.topicTitle}</h5>
                                                <span>{topic.date}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Cancelled Topics</h3>
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {topics.filter(i => i.topicStatus == TopicStatusEnum.Cancelled).map((topic, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-warning d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-warning pe-0 pe-sm-10">
                                                <h5 className="mb-1">{topic.topicTitle}</h5>
                                                <span>{topic.date}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
