import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { ExamFormModal } from './_modals/ExamFormModal'
import { ExamListModal } from './_modals/ExamListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import ExamApi from '../../../infrastructure/api/ExamApi'
import { Link, Route, Routes } from 'react-router-dom'

const ExamPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const examsBreadCrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]
  const examBreadCrumbs: Array<PageLink> = [
    {
      title: 'Exams',
      path: `/exam/exams`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Educall',
      path: '#',
      isSeparator: true,
      isActive: false,
    },
  ]


  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }
  async function deleteExam(itemId: string) {
    var response = await ExamApi.DeleteExam(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>

        <Routes>
          <Route path={'/exams/edit/:id'} element={<>
            <PageTitle breadcrumbs={examBreadCrumbs}>Exam</PageTitle>
            <ExamFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          </>}>
          </Route>
          <Route path={'/exams/add'} element={<>
            <PageTitle breadcrumbs={examBreadCrumbs}>Exam</PageTitle>
            <ExamFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          </>}>
          </Route>
          <Route path='/exams' element={<>
            <PageTitle breadcrumbs={examsBreadCrumbs}>Exams</PageTitle>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'></h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a Exam'
              >
                <Link
                  to={'/exam/exams/add'}
                  className='btn btn-sm btn-light-primary'
                >
                  <i className="fas fa-plus"></i>
                  Add Exam
                </Link>

              </div>
            </div>

            <div className='card-body py-3'>
              <ExamListModal refresh={refresh} onClickItem={onClickItem} />
              <DeleteModal
                itemId={itemId}
                itemName={itemName}
                handleClose={handleCloseDelete}
                show={showDelete}
                type='Exam'
                confirm={deleteExam}
              />
            </div>
          </>}>
          </Route>
        </Routes>


      </div>
    </>
  )
}

const ExamWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <ExamPage />
    </>
  )
}

export { ExamWrapper }
