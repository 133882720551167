import API from './Api'
import { IGetJobInterviewsRequestModel } from '../../models/requests/GetJobInterviewsRequestModel'
import { IJobInterviewsModel } from '../../models/responses/JobInterviewsModal'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'

class PlacementApi {

    async GetJobInterviews(filter: IPaginationFilter, model: IGetJobInterviewsRequestModel) {
        return await API.post<PagedResponseModel<IJobInterviewsModel[]>>(`s/CompanyManager/Placements/JobInterviews${generateQueryParams(filter)}`, model)
    }
}

export default new PlacementApi()
