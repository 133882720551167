import { shallowEqual, useSelector } from 'react-redux'
import { RoleEnum } from '../../../../../enums/RoleEnum'
import { IUserModel } from '../../../../../models/responses/UserModel'
import { RootState } from '../../../../../setup'
import { StudentDetails } from './cards/StudentDetails'
import { TrainerDetails } from './cards/TrainerDetails'

export function Settings() {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  return (
    <>
      <div className='row'>
        {user.roles?.some(s => s == RoleEnum.Student) &&
          <div className='col'>
            <StudentDetails />
          </div>
        }
        {user.roles?.some(s => s == RoleEnum.Trainer) &&
          <div className='col'>
            <TrainerDetails />
          </div>
        }
      </div>
    </>
  )
}
