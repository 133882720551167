import { FC, useEffect } from 'react'
import parse from 'html-react-parser'

type Props = {
    loading: boolean,
    setLoading: (status: boolean) => void
    complete: boolean
    setComplete: (status: boolean) => void
    submit: boolean
    setSubmit: () => void
    valid: boolean
    title?: string
    customClasses?: string
}


const SaveChangesButton: FC<Props> = ({ loading, setLoading, complete, setComplete, submit, setSubmit, valid, title = "Save Changes", customClasses = "" }) => {

    const onClick = () => {
        try {
            setSubmit();
            if (valid) {
                setLoading(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (complete) {
            setLoading(false)
            setTimeout(() => {
                setComplete(false)
            }, 2000)
        }
    }, [complete])

    return (

        <button
            disabled={loading || complete || !valid}
            onClick={() => onClick()}
            className={'btn btn-' + (!complete ? 'primary' : 'success') + " " + customClasses}>
            {(!loading && !complete) && parse(title)}
            {(loading && !complete && valid) && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
            {(!loading && complete) && (
                <span style={{ display: 'block' }}>
                    <i className="fas fa-check"></i> Saved
                </span>
            )}
        </button>
    )
}

export { SaveChangesButton }
