import { SelectModel } from '../models/SelectModel'

export const EnumToList = (data: any) => {
    const values: any = Object.values(data);
    var list: SelectModel[] = []
    for (let i = 0; i < (values.length / 2); i++) {
        list.push({
            label: values[i].toString(),
            value: values[(i + (parseInt(values.length) / 2))].toString()
        })
    }
    return list;
}