import { FC, useEffect } from "react"
import { IExamAnswerFormModel } from "../../../models/ExamAnswerFormModel"
import { IExamQuestionFormModel, initialExamQuestionFormModelValues } from "../../../models/ExamQuestionFormModel"
import { SelectModel } from '../../../models/SelectModel'
import Select from 'react-select'
import { customSelectStyles } from "../../../helpers/ReactSelectHelper"
import { Answers } from "./Answers"
import { DefaultEditor } from "../../../components/editors/DefaultEditor"
import { EditorToolbarEnum } from "../../../enums/EditorToolbarEnum"
import { FileTypeEnum } from "../../../../enums/FileTypeEnum"

type Props = {
    questions: IExamQuestionFormModel[]
    setQuestions: (questions: IExamQuestionFormModel[], index?: number) => void
    questionTypes: SelectModel[]
    getInput: (name: string) => HTMLInputElement
}

export const Questions: FC<Props> = ({ questions, setQuestions, questionTypes, getInput }) => {
    const setAnswers = (answers: IExamAnswerFormModel[], index?: number) => {
        var newQuestions = questions!!
        if (newQuestions[index!!]) {
            newQuestions[index!!].answers = answers
        }
        setQuestions([...newQuestions], index)
    }

    const addNewQuestion = () => {
        var newQuestions = questions!!
        if (!newQuestions) newQuestions = []
        newQuestions.push({ title: '', questionType: 0, comment: '', order: 0, answers: [] })
        setQuestions([...newQuestions])
    }

    const handleChangeTitle = (e: any, index: number) => {
        var newQuestions = questions!!
        if (newQuestions[index]) {
            newQuestions[index].title = e.target.value
        }
        setQuestions([...newQuestions], index)
    }

    const handleChangeOrder = (e: any, index: number) => {
        var newQuestions = questions!!
        if (newQuestions[index]) {
            newQuestions[index].order = parseInt(e.target.value)
        }
        setQuestions([...newQuestions])
    }

    const handleChangeComment = (e: any, index: number) => {
        var newQuestions = questions!!
        if (newQuestions[index]) {
            newQuestions[index].comment = e.target.value
        }
        setQuestions([...newQuestions])
    }

    const handleChangeQuestionType = (e: any, index: number) => {
        var newQuestions = questions!!
        if (newQuestions[index]) {
            newQuestions[index].questionType = parseInt(e.value)
        }
        setQuestions([...newQuestions])
    }

    const handleChangeText = (value: string, index?: number) => {
        var newQuestions = questions!!
        if (newQuestions[index!!]) {
            newQuestions[index!!].text = value
        }
        setQuestions([...newQuestions])
    }

    const handleChangeFile = (event: any, index: number) => {
        if (event.target.files[0] != null) {
            var newFiles = questions!!
            if (newFiles[index]) {
                newFiles[index].file = event.target.files[0]
                newFiles[index].filePath = `(${(event.target.files[0].size / 1000).toFixed()} KB) ${event.target.files[0].name}`
            }
            setQuestions([...newFiles])
        }
    }

    return (
        <>
            <h2>Questions</h2>
            <hr />
            {questions?.map((item, index) => {
                return (
                    <div key={index} className="card card-custom bg-light-success border border-dashed border-success gutter-b mb-3">
                        <div className="card-body">
                            <div className='row'>
                                {((questions[index].image != null && questions[index].image != '') ||
                                    (questions[index].video != null && questions[index].video != '') ||
                                    (questions[index].sound != null && questions[index].sound != '')) &&
                                    <div className="col-lg-12">
                                        <div className="card shadow mb-6 bg-white p-5 gutter-b align-items-center">
                                            {questions[index].image != null && questions[index].image != '' ?
                                                <img style={{ "height": "200px" }} src={`${process.env.REACT_APP_CDN_URL}files/${FileTypeEnum.Exam.toLowerCase()}/${questions[index].image}`} />
                                                : <></>
                                            }
                                            {questions[index].sound != null && questions[index].sound != '' ?
                                                <audio controls>
                                                    <source src={`${process.env.REACT_APP_CDN_URL}files/${FileTypeEnum.Exam.toLowerCase()}/${questions[index].sound}`} />
                                                </audio> : <></>
                                            }
                                            {questions[index].video != null && questions[index].video != '' ?
                                                <video width="auto" height="200" controls>
                                                    <source src={`${process.env.REACT_APP_CDN_URL}files/${FileTypeEnum.Exam.toLowerCase()}/${questions[index].video}`} />
                                                </video> : <></>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className='col-lg-11 mb-6'>
                                    <label className='form-label fw-bolder required'>Title</label>
                                    <div className="input-group">
                                        <input
                                            type='text'
                                            name={`title${index}`}
                                            defaultValue={item.title}
                                            onChange={(event) => handleChangeTitle(event, index)}
                                            className='form-control'
                                            placeholder='Entry..'
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-1 mb-6'>
                                    <label className='form-label fw-bolder required'>Order</label>
                                    <div className="input-group">
                                        <input
                                            type='text'
                                            name={`order${index}`}
                                            defaultValue={item.order}
                                            onChange={(event) => handleChangeOrder(event, index)}
                                            className='form-control'
                                            placeholder='Entry..'
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-lg-8 mb-6">
                                            <label className='form-label fw-bolder required'>Before to title</label>
                                            <DefaultEditor
                                                toolbars={[EditorToolbarEnum.Basic]}
                                                setData={handleChangeText}
                                                index={index}
                                                data={item.text!!}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className='mb-6'>
                                                <label className='form-label fw-bolder required'>Question Type</label>
                                                <Select
                                                    styles={customSelectStyles(false)}
                                                    options={questionTypes}
                                                    onChange={(event) => handleChangeQuestionType(event, index)}
                                                    value={questionTypes.find(i => i.value == item.questionType?.toString())}
                                                />
                                            </div>
                                            <div className='mb-6'>
                                                <label className='form-label fw-bolder required'>Comment</label>
                                                <div className="input-group">
                                                    <input
                                                        type='text'
                                                        name={`comment${index}`}
                                                        defaultValue={item.comment}
                                                        onChange={(event) => handleChangeComment(event, index)}
                                                        className='form-control'
                                                        placeholder='Entry..'
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-6'>
                                                <label className="alert alert-primary align-items-center d-flex bg-light-primary border-dashed"
                                                    style={{ cursor: "pointer" }}
                                                    data-kt-image-input-action='change'
                                                    data-bs-toggle='tooltip'
                                                    title=''
                                                    data-bs-original-title='Change file'
                                                >
                                                    <input
                                                        hidden
                                                        type='file'
                                                        value={undefined}
                                                        onChange={(event) => handleChangeFile(event, index)}
                                                        accept='.png, .jpg, .jpeg, .gif, .mp3, .wav, .mp4'
                                                    />
                                                    <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z" fill="black"></path>
                                                            <path d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z" fill="black"></path>
                                                            <path d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z" fill="black"></path>
                                                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                    <div className="d-flex flex-column">
                                                        <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Click to upload file</h3>
                                                        <span className="fw-bold fs-7 text-gray-400">{questions[index].filePath}</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    {(questions[index] && questions[index].answers) &&
                                        <Answers
                                            answers={questions!![index].answers!!}
                                            setAnswers={setAnswers}
                                            questionIndex={index} />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div style={{ textAlign: 'right' }}>
                <button type='button' onClick={() => addNewQuestion()} className='btn btn-info'>
                    <i className='fa fa-plus'></i> Add Question
                </button>
            </div>
        </>
    )
}