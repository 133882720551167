import { FC } from 'react'
import { Link } from 'react-router-dom'
import { DictionaryModel } from '../../../models/responses/DictionaryModel'
import { FunctionDictionaryModel } from '../../../models/responses/FunctionDictionaryModel'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { TableHelperButtonEnum } from '../../enums/TableHelperButtonEnum'

type Props = {
  itemId?: any
  itemParam?: string
  links?: DictionaryModel[]
  functions?: FunctionDictionaryModel[]
  types: Array<string>
  onClickItem: (itemId: string, itemName: string, type: string) => void
}
const TableHelperButtons: FC<Props> = ({ itemId, itemParam, links, functions, types, onClickItem }) => {
  return (
    <div className='d-flex justify-content-end flex-shrink-0'>
      {functions?.map((item, i) => {
        return (
          <a
            key={i}
            href='#'
            onClick={() => item.function()}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <i className={`fas fa-${item.type} text-gray-500`} ></i>
          </a>
        )
      })}
      {links?.map((item, i) => {
        return (
          <Link
            key={i}
            to={item.value}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <i className={`fas fa-${item.type} text-gray-500`} ></i>
          </Link>
        )
      })}
      {types.find((i) => i == TableHelperButtonEnum.View) && (
        <a
          href='#'
          onClick={() => onClickItem(itemId!!, itemParam!!, TableHelperButtonEnum.View)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <i className='fa fa-eye'></i>
        </a>
      )}
      {types.find((i) => i == TableHelperButtonEnum.Update) && (
        <a
          href='#'
          onClick={() => onClickItem(itemId!!, itemParam!!, TableHelperButtonEnum.Update)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
        </a>
      )}
      {types.find((i) => i == TableHelperButtonEnum.Reply) && (
        <a
          href='#'
          onClick={() => onClickItem(itemId!!, itemParam!!, TableHelperButtonEnum.Reply)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-3' />
        </a>
      )}
      {types.find((i) => i == TableHelperButtonEnum.Delete) && (
        <a
          href='#'
          onClick={() => onClickItem(itemId!!, itemParam!!, TableHelperButtonEnum.Delete)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      )}
    </div>
  )
}

export { TableHelperButtons }
