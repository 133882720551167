import { FC, useEffect, useState } from 'react'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import { ICompanyModel, companyInitValues } from '../../../../models/responses/CompanyModel'
import { ICompanyRequestModel } from '../../../../models/requests/CompanyRequestModel'
import { CompanyFormValidations } from '../../../validations/CompanyFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import { CompanyTypeEnum } from '../../../../enums/CompanyTypeEnum'
import { slugify } from '../../../helpers/StringHelper'
import FileApi from '../../../../infrastructure/api/FileApi'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { string } from 'yup/lib/locale'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

type Props = { itemId: string; show: boolean; handleClose: () => void }

const CompanyFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [loadLogoFile, setLoadLogoFile] = useState<any>(undefined)
  const [loadFile, setLoadFile] = useState<any>(undefined)
  const [logo, setLogo] = useState('')
  const [background, setBackground] = useState('')
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [company, setCompany] = useState<ICompanyModel>(companyInitValues)
  const updateCompany = (fieldsToUpdate: Partial<ICompanyModel>) => {
    const updatedData = { ...company, ...fieldsToUpdate }
    setCompany(updatedData)
  }

  const companyTypeList = EnumToList(CompanyTypeEnum)
  const [selectedCompanyType, setSelectedCompanyType] = useState<SelectModel>(companyTypeList[0])


  const [parentCompanies, setParentCompanies] = useState<SelectModel[]>([])
  const [selectedParentCompany, setSelectedParentCompany] = useState<SelectModel>()

  const formik = useFormik<ICompanyModel>({
    initialValues: company,
    enableReinitialize: true,
    validationSchema: CompanyFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ICompanyRequestModel = {
          id: values.id,
          name: values.name,
          title: values.title,
          phone: values.phone,
          website: values.website,
          logo: values.logo,
          background: values.background,
          parentCompanyId: selectedParentCompany?.value,
          companyType: CompanyTypeEnum.Section,
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: ICompanyRequestModel) {
    try {
      if (loadLogoFile !== undefined) {
        let d = new Date()
        const fileName = slugify(`${model.title}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadLogoFile)
        modelPicture.append('type', FileTypeEnum.CompanyLogo)
        await FileApi.AddFile(modelPicture)
        model.logo = fileName + '.' + loadLogoFile.name.split('.').pop()
      }
      if (loadFile !== undefined) {
        let d = new Date()
        const fileName = slugify(`${model.title}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadFile)
        modelPicture.append('type', FileTypeEnum.CompanyBackground)
        await FileApi.AddFile(modelPicture)
        model.background = fileName + '.' + loadFile.name.split('.').pop()
      }

      model.parentCompanyId = selectedParentCompany?.value;

      var result = undefined
      if (model.id === undefined || model.id === '') {
        result = await CompanyApi.AddCompany(model)
      } else {
        result = await CompanyApi.UpdateCompany(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    if (user.companyId) {
      var responseParentCompanies = await CompanyApi.GetSubCompaniesDictionary(user.companyId)
      var dataParentCompanies: SelectModel[] = [
        ...responseParentCompanies.data.map((d) => ({
          value: d.id.toString(),
          label: d.name,
        })),
      ]
      setParentCompanies(dataParentCompanies)

      let result: ICompanyModel
      if (itemId != '') {
        var response = await CompanyApi.GetCompany(itemId)
        result = response.data
        handleChangeParentCompany(
          response.data.parentCompanyId
            ? dataParentCompanies.find((i) => i.value === response.data.parentCompanyId.toString())
            : dataParentCompanies[0]
        )
      } else {
        result = companyInitValues
        handleChangeParentCompany(
          dataParentCompanies[0]
        )
      }

      handleChangeCompanyType(
        result.companyType
          ? companyTypeList.find((i) => i.value == result.companyType.toString())
          : companyTypeList[0]
      )

      setLogo(
        result.logo !== ''
          ? `${process.env.REACT_APP_CDN_URL}images/company/${result.logo}`
          : toAbsoluteUrl('/media/misc/none.png')
      )
      setBackground(
        result.background !== ''
          ? `${process.env.REACT_APP_CDN_URL}images/company/${result.background}`
          : toAbsoluteUrl('/media/misc/none.png')
      )
      setCompany(result)
    }
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeLogo = (event: any) => {
    setLoadLogoFile(event.target.files[0])
    setLogo(URL.createObjectURL(event.target.files[0]))
  }

  const handleChangeBackground = (event: any) => {
    setLoadFile(event.target.files[0])
    setBackground(URL.createObjectURL(event.target.files[0]))
  }

  const handleChangeParentCompany = (e: any) => {
    setSelectedParentCompany(e)
  }

  const handleChangeCompanyType = (e: any) => {
    setSelectedCompanyType(e)
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Section</h1>
              <div className='text-muted fw-bold fs-5'>
                Section Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-12'>
                <label className='form-label fw-bolder'>Parent Section</label>
                <Select
                  {...formik.getFieldProps('parentCompanyId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeParentCompany(event)}
                  value={selectedParentCompany}
                  options={parentCompanies}
                />
                <FormikValidationError
                  touched={formik.touched.parentCompanyId}
                  error={formik.errors.parentCompanyId}
                />
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-4'>
                <div className='mb-20'>
                  <label className='form-label fw-bolder'>Logo</label>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`,
                        minWidth: '100%',
                      }}
                    >
                      <div
                        className='image-input-wrapper'
                        style={{
                          backgroundImage: `url(${logo})`,
                          minWidth: '100%',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                        }}
                      ></div>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Change logo'
                      >
                        <i className='fas fa-pencil-alt'></i>
                        <input
                          {...formik.getFieldProps('logo')}
                          type='file'
                          value={undefined}
                          onChange={(event) => handleChangeLogo(event)}
                          accept='.png, .jpg, .jpeg'
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Cancel logo'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                    <FormikValidationError
                      touched={formik.touched.logo}
                      error={formik.errors.logo}
                    />
                  </div>
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Background</label>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`,
                        minWidth: '100%',
                      }}
                    >
                      <div
                        className='image-input-wrapper'
                        style={{
                          backgroundImage: `url(${background})`,
                          minWidth: '100%',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                        }}
                      ></div>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Change avatar'
                      >
                        <i className='fas fa-pencil-alt'></i>
                        <input
                          {...formik.getFieldProps('background')}
                          type='file'
                          value={undefined}
                          onChange={(event) => handleChangeBackground(event)}
                          accept='.png, .jpg, .jpeg'
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                    <FormikValidationError
                      touched={formik.touched.background}
                      error={formik.errors.background}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-8'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Name</label>
                  <input
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder required'>Title</label>
                  <input
                    {...formik.getFieldProps('title')}
                    type='text'
                    name='title'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError
                    touched={formik.touched.title}
                    error={formik.errors.title}
                  />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Web Site</label>
                  <input
                    {...formik.getFieldProps('website')}
                    type='text'
                    name='website'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError
                    touched={formik.touched.website}
                    error={formik.errors.website}
                  />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Phone</label>
                  <input
                    {...formik.getFieldProps('phone')}
                    type='text'
                    name='phone'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError
                    touched={formik.touched.phone}
                    error={formik.errors.phone}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { CompanyFormModal }
