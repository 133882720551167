import { ScheduleStatusEnum } from "../../enums/ScheduleStatusEnum";

export function ScheduleStatusColorizer(status: ScheduleStatusEnum) {
    var color = "primary";
    switch (status) {
        case ScheduleStatusEnum.Completed:
            color = "success"
            break;
        case ScheduleStatusEnum.Canceled:
            color = "info"
            break;
        case ScheduleStatusEnum.MeetingCreateError:
            color = "light-danger text-danger"
            break;
        case ScheduleStatusEnum.TechnicalDifficulty:
            color = "danger"
            break;
        case ScheduleStatusEnum.NotAnswered:
            color = "warning"
            break;
        case ScheduleStatusEnum.Closed:
            color = "dark"
            break;
        case ScheduleStatusEnum.Ready:
            color = "light-primary text-primary"
            break;
        case ScheduleStatusEnum.Started:
            color = "primary"
            break;
        default:
            color = "secondary"
            break;
    }

    return color;
}