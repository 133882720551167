import { IQuestionModel } from "./QuestionModel"

export interface IExamModel {
    id: string,
    title: string,
    examTypeId: string,
    unitId: number,
    levelId: number,
    isRandomQuestion: boolean,
    questionNumber: number,
    questions: IQuestionModel[]
}

const examInitValues: IExamModel = {
    id: '',
    title: '',
    examTypeId: '',
    levelId: 0,
    unitId: 0,
    isRandomQuestion: false,
    questionNumber: 0,
    questions: []
}

export { examInitValues }