import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IScheduleModel, ISchedulesModel } from '../../models/responses/ScheduleModel'
import { IGetSchedulesRequestModel } from '../../models/requests/GetSchedulesRequestModel'
import { IScoreStatisticsRequestModel } from '../../models/requests/ScoreStatisticsRequestModel'
import { IScoreStatisticsModel } from '../../models/responses/ScoreStatisticsModel'
import { IStudentReportsModel } from '../../models/responses/StudentReportsModel'
import { IStudentReportsRequestModel } from '../../models/requests/StudentReportsRequestModel'

class ScheduleApi {
    async GetSchedule(id: string) {
        return await API.get<IScheduleModel>('s/CompanyManager/Schedules/' + id)
    }

    async GetPreviousSchedules(filter: IPaginationFilter, model: IGetSchedulesRequestModel) {
        return await API.post<PagedResponseModel<ISchedulesModel[]>>(`s/CompanyManager/Schedules/Previous${generateQueryParams(filter)}`, model)
    }

    async GetScoreStatistics(filter: IPaginationFilter, model: IScoreStatisticsRequestModel) {
        return await API.post<PagedResponseModel<IScoreStatisticsModel[]>>(`s/CompanyManager/Schedules/ScoreStatistics${generateQueryParams(filter)}`, model)
    }

    async GetStudentReports(filter: IPaginationFilter, model: IStudentReportsRequestModel) {
        return await API.post<PagedResponseModel<IStudentReportsModel[]>>(`s/CompanyManager/Schedules/StudentReports${generateQueryParams(filter)}`, model)
    }

    async GetSchedules(filter: IPaginationFilter, model: IGetSchedulesRequestModel) {
        return await API.post<PagedResponseModel<ISchedulesModel[]>>(`s/CompanyManager/Schedules/List/${generateQueryParams(filter)}`, model)
    }
}

export default new ScheduleApi()