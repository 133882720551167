import { ScheduleStatusEnum } from '../../enums/ScheduleStatusEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'

export interface IScheduleModel {
    id?: string,
    studentIds: string[],
    trainerId: string,
    companyId?: string,
    type: ScheduleTypeEnum,
    levelId?: number,
    topicId?: string,
    capacity: number,
    duration: number,
    empty: number,
    status: ScheduleStatusEnum
    plannedDate: string,
    completedDate?: string,
    details: IScheduleDetailModel[],
    trainerComments: ITrainerCommentModel[],
    studentComments: IStudentCommentModel[],
    records: IScheduleRecordModel[]
}

export interface IScheduleDetailModel {
    createdAt: string
    trainerId: string
    status: number
}

export interface IScheduleRecordModel {
    url: string,
    fileType: string
}

export interface ITrainerCommentModel {
    trainerId: string
    trainerFullName: string
    studentIds: []
    scheduleId: string
    isAutoComment: boolean
    isForStudent: boolean
    message: string
    privateMessage: string
    directMessage: string
    date: string
}

export interface IStudentCommentModel {
    message: string
    date: string
    pleasure: 0
    difficulty: 0
    useful: 0
    quality: 0
    prepared: 0
}

const scheduleInitValues: IScheduleModel = {
    id: undefined,
    studentIds: [],
    trainerId: '',
    companyId: '',
    type: ScheduleTypeEnum.OneTime,
    levelId: 0,
    topicId: '',
    capacity: 0,
    duration: 0,
    empty: 0,
    status: ScheduleStatusEnum.Waiting,
    plannedDate: '',
    completedDate: '',
    details: [],
    trainerComments: [],
    studentComments: [],
    records: []
}

export interface ISchedulesModel {
    id?: string,
    studentIds: string[],
    trainerId: string,
    companyId?: string,
    type: ScheduleTypeEnum,
    levelId?: number,
    topicId?: string,
    duration?: number,
    capacity: number,
    empty: number,
    status: ScheduleStatusEnum
    plannedDate: string,
    completedDate?: string
}

const schedulesInitValues: ISchedulesModel = {
    id: undefined,
    studentIds: [],
    trainerId: '',
    companyId: '',
    type: ScheduleTypeEnum.Planned,
    levelId: undefined,
    topicId: undefined,
    duration: 0,
    capacity: 0,
    empty: 0,
    status: ScheduleStatusEnum.Waiting,
    plannedDate: '',
    completedDate: ''
}

export { scheduleInitValues, schedulesInitValues }
