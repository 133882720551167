export interface IPaginationFilter {
  page: number,
  size: number,
  search?: string,
  orderBy?: string,
  filters?: Map<string, string>,
  filterMultiple?: FilterMulti[]
}

export interface FilterMulti {
  key: string;
  value: string;
}

export const generateQueryParams = (paginationFilter: IPaginationFilter) => {
  let query = `?pageNumber=${paginationFilter.page}&pageSize=${paginationFilter.size}`
  if (paginationFilter?.search!! != "") query += `&search=${paginationFilter.search}`
  if (paginationFilter?.orderBy!! != "") query += `&orderBy=${paginationFilter.orderBy}`
  if (paginationFilter?.filters?.size!! > 0) {
    for (let [key, value] of Array.from(paginationFilter.filters!!)) {
      query += `&filters[${key}]=${value}`
    }
  }
  if (paginationFilter?.filterMultiple?.length!! > 0) {
    let lastFilterKey = "";
    for (let filter of Array.from(paginationFilter.filterMultiple!!)) {
      if (lastFilterKey != filter.key) {
        lastFilterKey = filter.key;
        query += `&filterMultiple[${filter.key}]=${filter.value}`
      } else {
        query += `,${filter.value}`
      }
    }
  }
  return query
}