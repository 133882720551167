import { IChangeStudentBlockedTrainersRequestModel } from '../../models/requests/ChangeStudentBlockedTrainersRequestModel'
import { IDeleteStudentPlanRequestModel } from '../../models/requests/DeleteStudentPlanRequestModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IStudentModel, IStudentsModel } from '../../models/responses/StudentModel'
import { IAddStudentPlanRequestModel } from '../../models/requests/AddStudentPlanRequestModel'
import { IGetStudentPlansRequestModel } from '../../models/requests/GetStudentPlansRequestModel'
import { IStudentRequestModel } from '../../models/requests/StudentRequestModel'

class StudentApi {

    async GetStudent(id: string) {
        return await API.get<IStudentModel>('s/CompanyManager/Students/' + id)
    }

    async GetStudentPlans(filter: IPaginationFilter, model: IGetStudentPlansRequestModel) {
        return await API.post<PagedResponseModel<IStudentsModel[]>>(`s/CompanyManager/Students/${generateQueryParams(filter)}`, model)
    }

    async BlockedTrainer(model: IChangeStudentBlockedTrainersRequestModel) {
        return await API.put('s/CompanyManager/Students/BlockedTrainer', model)
    }

    async AddStudentPlan(model: IAddStudentPlanRequestModel) {
        return await API.patch('s/CompanyManager/Students/AddPlan', model)
    }

    async UpdateStudent(model: IStudentRequestModel) {
        return await API.put('s/CompanyManager/Students/' + model.id, model)
    }

    async DeleteCallPlan(model: IDeleteStudentPlanRequestModel) {
        return await API.patch<boolean>('s/CompanyManager/Students/DeletePlan', model)
    }
}

export default new StudentApi()
