import { DayOfWeekEnum } from '../../enums/DayOfWeekEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'

export interface IAddStudentPlanRequestModel {
    studentId: string,
    startDate: string,
    dayOfWeek: DayOfWeekEnum,
    duration: number,
    type: ScheduleTypeEnum,
    trainerId?: string
}

const addStudentPlanInitValues = {
    studentId: '',
    startDate: '',
    dayOfWeek: DayOfWeekEnum.Friday,
    duration: 0,
    type: ScheduleTypeEnum.Planned,
    trainerId: undefined
}

export { addStudentPlanInitValues }
