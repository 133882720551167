import { FC, useEffect, useState } from 'react'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import {
  IResultModel,
  resultInitValues,
} from '../../../../models/responses/ResultModel'
import { SelectModel } from '../../../models/SelectModel'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import parse from 'html-react-parser'

import { PieDonutChart } from '../../../components/charts'
import { MixedWidget1, StatisticsWidget1 } from '../../../../_metronic/partials/widgets'
import { BasicTable } from '../../../components/tables/BasicTable'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ResultViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [result, setResult] = useState<IResultModel>(resultInitValues)
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const updateResult = (fieldsToUpdate: Partial<IResultModel>) => {
    const updatedData = { ...result, ...fieldsToUpdate }
    setResult(updatedData)
  }

  async function init() {
    let result: IResultModel
    if (itemId !== '') {
      var response = await ResultApi.GetResult(itemId)
      result = response.data

      result.choices.forEach(item => {
        item.status = item.isCorrect ? 'Correct' : item.isEmpty ? 'Empty' : 'Wrong'
      });

      if (response.data.evaluation != '') {
        var responseUsers = await UserApi.GetUserDictionaryIds([response.data.evaluativeId])
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        setUsers(dataUsers)
      }

    } else {
      result = resultInitValues
    }


    setResult(result)
  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>

        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-3'>
            <h1 className='mb-3'>Exam Result</h1>
            <div className='text-muted fw-bold fs-5'>
              Exam Management <small>Educall</small>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'>
              <div className={`card card-xl-stretch mb-xl-8`}>
                <div className='card-body p-0'>
                  <div className={`px-9 pt-2 card-rounded h-225px w-100 bg-primary`}>
                    <div className='d-flex text-center flex-column text-white pt-8'>
                      <span className='fw-bold fs-7'>Exam</span>
                      <span className='fw-bolder fs-2x pt-1'>{result.title}</span>
                    </div>
                  </div>
                  <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                    style={{ marginTop: '-100px' }}
                  >
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG path='/media/icons/duotone/Home/Globe.svg' className='svg-icon-1' />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            Date
                          </a>
                          <div className='text-gray-400 fw-bold fs-7'>Start: <span className='text-dark'>{result.startTime}</span> <br /> Finish: <span className='text-dark'>{result.endTime}</span></div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      {/* begin::Symbol */}
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG
                            path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            Safe Exam
                          </a>
                          <div className='text-gray-400 fw-bold fs-7'><span className='text-dark'>{result.isSafeExam ? 'Yes' : 'No'}</span></div>
                        </div>
                      </div>
                    </div>
                    {result.isWriting &&
                      <>
                        {result.isEvaluated &&
                          <>
                            <div className='d-flex align-items-center mb-6'>
                              <div className='symbol symbol-45px w-40px me-5'>
                                <span className='symbol-label bg-lighten'>
                                  <KTSVG path='/media/icons/duotone/Home/Flower2.svg' className='svg-icon-1' />
                                </span>
                              </div>
                              <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    Evaluation
                                  </a>
                                  <div className='text-gray-400 fw-bold fs-7'>Evaluative: <span className='text-dark'>{users.find(i => i.id == result.evaluativeId)?.email}</span><br /> Date: <span className='text-dark'>{result.evaluationDate}</span></div>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-center mb-6'>
                              <div className='symbol symbol-45px w-40px me-5'>
                                <span className='symbol-label bg-lighten'>
                                  <KTSVG path='/media/icons/duotone/Home/Wood2.svg' className='svg-icon-1' />
                                </span>
                              </div>
                              <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    Score
                                  </a>
                                  <div className='text-gray-400 fw-bold fs-7'><span className='text-dark'>{result.score}/30</span></div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        <div className='d-flex align-items-center mb-6'>
                          <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                              <KTSVG path='/media/icons/duotone/Home/Air-ballon.svg' className='svg-icon-1' />
                            </span>
                          </div>
                          <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                              <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                Copy Percentage
                              </a>
                              <div className='text-gray-400 fw-bold fs-7'><span className='text-dark'>%{result.copyPercentage}</span></div>
                            </div>
                          </div>
                        </div>
                      </>}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-7'>
              {!result.isWriting ?
                <PieDonutChart type='donut' size={500}
                  series={[result.totalCorrect, result.totalWrong, result.totalEmpty]}
                  labels={["Correct", "Wrong", "Empty"]}
                  text={`%${result.success}`}
                /> : <div className='alert alert-custom bg-light-primary'>
                  <h4 className="alert-heading">Essay</h4>
                  {parse(result.choices[0].answers.join(''))}</div>
              }
            </div>
            {result.isWriting && result.evaluation != '' &&
              <div className='col-lg-12'>
                <div className='alert alert-custom bg-light-success'>
                  <h4 className="alert-heading">Evaluation</h4>
                  {parse(result.evaluation)}</div>
              </div>
            }
            {!result.isWriting &&
              <div className='col-lg-12'>
                <h2>Questions</h2>
                <hr />
                <BasicTable
                  title=''
                  data={result.choices}
                  columns={[
                    {
                      label: 'Status', name: 'status',
                      options: {
                        customBodyRender: (value) => {
                          return (
                            <>
                              {value == "Correct" ? <KTSVG path='/media/icons/duotone/Code/Done-circle.svg' className='svg-icon svg-icon-2x svg-icon-success' />
                                : (value == "Wrong" ? <KTSVG path='/media/icons/duotone/Code/Error-circle.svg' className='svg-icon svg-icon-2x svg-icon-danger' />
                                  : <KTSVG path='/media/icons/duotone/Code/Info-circle.svg' className='svg-icon svg-icon-2x svg-icon-dark' />
                                )
                              }
                            </>
                          )
                        },
                        filterOptions: { names: ['Correct', 'Wrong', 'Empty'] }
                      },

                    },
                    {
                      label: 'Question',
                      name: 'questionTitle',
                      options: { filter: false }
                    },
                    {
                      label: 'Answers', name: 'answers',
                      options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                          return (
                            <b>
                              {!result.choices[dataIndex].isCorrect && !result.choices[dataIndex].isEmpty &&
                                <>
                                  <del className='text-danger'>
                                    ({result.choices[dataIndex].answers?.join("), (")})
                                  </del><br />
                                </>
                              }
                              <span className={result.choices[dataIndex].isCorrect ? 'text-success' : 'text-dark'}>
                                ({result.choices[dataIndex].correctAnswers?.join("), (")})
                              </span>
                            </b>
                          )
                        },
                      },
                    },
                  ]}
                  customOptions={{
                    filter: true,
                    viewColumns: false,
                    serverSide: false,
                    customTableBodyFooterRender: (opts) => {
                      return <div></div>
                    },
                  }}
                />
              </div>
            }
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>

          </div>
        </div>
      </div>
    </Modal>
  )
}

export { ResultViewModal }
