/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
const AsideUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div className='d-flex flex-stack'>
        <div className='d-flex align-items-center'>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            <a href='#' className='text-gray-600 text-hover-primary fs-6 fw-bolder lh-1'>
              {user.rolesString}
            </a>
            <span className='text-muted fw-bold d-block fs-7 lh-1'>{user.email}</span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

      </div>
    </>
  )
}

export { AsideUserMenu }
