import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { IStudentsModel } from '../../../../models/responses/StudentModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const StudentListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('title_asc')

    const d = new Date();
    let weekDay = d.getDay()
    var now = `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`
    const [students, setStudents] = useState<PagedResponseModel<IStudentsModel[]>>()
    const [users, setUsers] = useState<IUserDictionaryModel[]>([])
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    async function init() {
        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await StudentApi.GetStudentPlans(paginationFilter, {
            dayOfWeek: DayOfWeekEnum.Monday,
            startDate: '00:00:00',
            endDate: '23:59:59',
            duration: 0,
            trainerId: '',
            companyId: user.companyId
        })
        setStudents(response.data)

        var responseUsers = await UserApi.GetUserDictionaryIds([...response.data.items.map(i => i.id!!), ...response.data.items.map(i => i.trainerId!!)])
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        setUsers(dataUsers)
    }

    useEffect(() => {
        init()
    }, [refresh])

    return (
        <>

            <GenericTable
                title='Student Plans'
                data={students?.items}
                currentTotalRecords={students?.totalRecords!!}
                currentPageNumber={students?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{dataIndex + 1}</div>
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "Name", name: "id", options: {
                            customBodyRender: (value) => {
                                return <b>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</b>
                            }
                        }
                    },
                    {
                        label: "Day", name: "dayOfWeek", options: {
                            customBodyRender: (value) => {
                                return <>{DayOfWeekEnum[value]}</>
                            }
                        }
                    },
                    {
                        label: "Start Date", name: "startDate", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Duration", name: "duration", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Type", name: "scheduleType", options: {
                            customBodyRender: (value) => {
                                return <>{ScheduleTypeEnum[value]}</>
                            }
                        }
                    },
                    {
                        label: "Is Assigned", name: "isAssigned", options: {
                            customBodyRender: (value) => {
                                return <div className='ps-5 fw-bolder text-success'>
                                    {value == true ? '✓' : null}
                                </div>
                            }
                        }
                    },
                    {
                        label: "Trainer", name: "trainerId", options: {
                            customBodyRender: (value) => {
                                return <b>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</b>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={students?.items[dataIndex]?.id!!}
                                        itemParam={students?.items[dataIndex]?.id!!}
                                        links={[
                                            { type: 'edit', value: '/account/users/' + students?.items[dataIndex]?.id + '/call-plans' }
                                        ]}
                                        types={[]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { StudentListModal }
