import { FC } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const SplashScreen: FC = () => {
    return (
        <div id="splash-screen" className="splash-screen">
            <img src={toAbsoluteUrl('/media/logos/logo.png')} alt="Educall" />
            <i className="fas fa-spinner fa-pulse fa-2x"></i>
        </div>
    )
}
export { SplashScreen }