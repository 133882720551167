import { IPartofSpeechModel } from '../../models/responses/PartofSpeechModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PartofSpeechApi {
  async GetPartofSpeechs(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPartofSpeechModel[]>>(`t/CompanyManager/PartofSpeechs${generateQueryParams(filter)}`)
  }
}

export default new PartofSpeechApi()
