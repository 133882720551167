/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CompanyApi from '../../../infrastructure/api/CompanyApi'
import DashboardApi from '../../../infrastructure/api/DashboardApi'
import { IScheduleStatisticsModel, scheduleStatisticsInitValues } from '../../../models/responses/ScheduleStatisticsModel'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_metronic/helpers'
// import {useIntl} from 'react-intl'
import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import { UserModel } from '../../modules/auth/models/UserModel'

const DashboardPage: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [scheduleStatistics, setScheduleStatistics] = useState<IScheduleStatisticsModel>(scheduleStatisticsInitValues)
  const init = async () => {
    var response = await DashboardApi.GetStatistics(user.companyId!);

    setScheduleStatistics(response.data);

    var responseData = await CompanyApi.GetCompany(user.companyId!)
  }
  useEffect(() => {
    init();
  }, [])
  // const intl = useIntl()
  return (
    <>
      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-4'>
        
          <div className={`card card-xl-stretch mb-xl-10`} style={{ backgroundColor: '#CBF0F4' }}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1'>
                {/* begin::Title                    */}
                <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                  Waiting Calls
                </a>
                <i className='fas fa-headset fa-2x text-dark mt-10 mb-10' />
                {/* end::Title */}

              </div>
              {/* end::Wrapper */}

              {/* begin::Stats */}
              <div className='pt-5'>
                {/* begin::Symbol */}
                <span className='text-dark fw-bolder fs-2x lh-0'></span>
                {/* end::Symbol */}

                {/* begin::Number */}
                <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{scheduleStatistics.waitingCallNumber}</span>
                {/* end::Number */}

                {/* begin::Text */}
                <span className='text-dark fw-bolder fs-6 lh-0'>calls</span>
                {/* end::Text */}
              </div>
              {/* end::Stats */}
            </div>
          </div>

        </div>
        <div className='col-xl-4'>
          <div className={`card card-xl-stretch mb-xl-10`} style={{ backgroundColor: '#F7D9E3' }}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1'>
                {/* begin::Title                    */}
                <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                  Active Calls
                </a>
                <i className='fas fa-phone-volume fa-2x text-dark mt-10 mb-10' />
                {/* end::Title */}

              </div>
              {/* end::Wrapper */}

              {/* begin::Stats */}
              <div className='pt-5'>
                {/* begin::Symbol */}
                <span className='text-dark fw-bolder fs-2x lh-0'></span>
                {/* end::Symbol */}

                {/* begin::Number */}
                <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{scheduleStatistics.startedCallNumber}</span>
                {/* end::Number */}

                {/* begin::Text */}
                <span className='text-dark fw-bolder fs-6 lh-0'>calls</span>
                {/* end::Text */}
              </div>
              {/* end::Stats */}
            </div>
          </div>

        </div>
        {/*end::Col*/}

        <div className='col-xl-4'>
          <div className={`card card-xl-stretch mb-xl-10`} style={{ backgroundColor: '#CBD4F4' }}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1'>
                {/* begin::Title                    */}
                <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                  Completed Calls
                </a>
                <i className='fas fa-phone fa-2x text-dark mt-10 mb-10' />
                {/* end::Title */}

              </div>
              {/* end::Wrapper */}

              {/* begin::Stats */}
              <div className='pt-5'>
                {/* begin::Symbol */}
                <span className='text-dark fw-bolder fs-2x lh-0'></span>
                {/* end::Symbol */}

                {/* begin::Number */}
                <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{scheduleStatistics.completedCallNumber}</span>
                {/* end::Number */}

                {/* begin::Text */}
                <span className='text-dark fw-bolder fs-6 lh-0'>calls</span>
                {/* end::Text */}
              </div>
              {/* end::Stats */}
            </div>
          </div>

        </div>
      </div>
      {/*end::Row*/}

    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle description='' breadcrumbs={[]}>
        DASHBOARD
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
