import { FC, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import PlacementApi from '../../../../infrastructure/api/PlacementApi'
import { IJobInterviewsModel } from '../../../../models/responses/JobInterviewsModal'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { SelectModel } from '../../../models/SelectModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import * as level from '../../../pages/levels/redux/LevelRedux'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { FunctionDictionaryModel } from '../../../../models/responses/FunctionDictionaryModel'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select';
import ReportApi from '../../../../infrastructure/api/ReportApi'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const JobInterviewsListModal: FC<Props> = ({ onClickItem, refresh }) => {

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  const [jobInterviews, setJobInterviews] = useState<PagedResponseModel<IJobInterviewsModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>()

  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");



  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getUsers(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }


  var innerHTML = "";

  async function init() {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    if (companies.length < 1 && user.companyId) {
      var companiesQuery = await CompanyApi.GetSubCompaniesDictionary(user.companyId);
      var dataParentCompanies: SelectModel[] = [
        ...companiesQuery.data.map((d) => ({
          value: d.id.toString(),
          label: d.name,
        })),
      ]
      setCompanies(dataParentCompanies)
    }
    getUsers(start, end);
  }

  async function downloadStudentInterviewPDF(studentId: string, levelId: number) {
    var userModel = jobInterviews?.items.find(i => i.studentId == studentId);

    var companyName = user.companyName;
    var date = moment().format("DD-MM-YYYY");
    var studentFullname = `${userModel?.firstName} ${userModel?.lastName}`

    var report = await ResultApi.GetJobInterviewReport(studentId, levelId);

    var title = `${companyName} - ${studentFullname} - ${date} Educall Report`;

    await ReportApi.GetJobInterviewReport({
      student: studentFullname,
      date: date,
      company: companyName,
      title: title,
      cefr: report.data.cefr,
      toeflListeningScore: report.data.toeflListeningScore,
      toeflReadingScore: report.data.toeflReadingScore,
      toeflWritingScore: report.data.toeflWritingScore,
      result: report.data.result,
      grammarCefrSuccess: report.data.grammarCefrSuccess,
      grammarLevelScore: report.data.grammarLevelScore,
      grammarSuccess: report.data.grammarSuccess,
      level: report.data.level
    });
  }

  useEffect(() => {
    init();
  }, [refresh, pageNumber, pageSize, searchText, sort, selectedCompany])

  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  async function getUsers(start: moment.Moment, end: moment.Moment) {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText
    }

    if (user.companyId && selectedCompany?.value) {

      var companies = await CompanyApi.GetSubCompaniesDictionary(selectedCompany.value);

      var jobInterviews = await PlacementApi.GetJobInterviews(
        paginationFilter, {
        companyIds: companies.data.map(i => i.id),
        startDate: start.format("YYYY-MM-DDT00:00:00"),
        endDate: end.format("YYYY-MM-DDT23:59:59")
      });

      setJobInterviews(jobInterviews.data);
    }
  }

  return (
    <>
      <div className='row' style={{ zIndex: 9999 }}>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder'>Company Section</label>
          <Select
            styles={customSelectStyles(true)}
            menuPortalTarget={document.body}
            onChange={(event) => handleChangeCompany(event)}
            value={selectedCompany}
            options={companies}
          />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder'>Date Range</label>
          <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            style={{
              darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
            }}
            end={end}
            local={local}
            applyCallback={applyCallback}
          >
            <input
              id="formControlsTextB"
              className='form-control form-control-solid'
              value={`${start.format(local.format)} - ${end.format(local.format)}`}
              type="text"
            />
          </DateTimeRangeContainer>
        </div>

      </div>
      <GenericTable
        title={'Job Interviews'}
        data={jobInterviews?.items}
        currentTotalRecords={jobInterviews?.totalRecords!!}
        currentPageNumber={jobInterviews?.pageNumber!!}
        columns={[
          {
            label: "#", name: "#", options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            }
          },
          {
            label: "FullName", name: "firstName", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{jobInterviews?.items[dataIndex].firstName} {jobInterviews?.items[dataIndex].lastName}</b>
                )
              },
            },
          },
          { label: "Email", name: "email" },
          {
            label: "Level", name: "levelId", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  jobInterviews?.items[dataIndex]?.levelId != undefined && <b>{levelDictionary.find(i => i.value == jobInterviews?.items[dataIndex]?.levelId.toString())?.label}</b>
                )
              },
            },
          },
          {
            label: "Date", name: "date", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{jobInterviews?.items[dataIndex]?.date}</b>
                )
              },
            },
          },
          {
            label: "#", name: "Id", options: {
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {

                var customFunctions: FunctionDictionaryModel[] = []
                if (jobInterviews?.items[dataIndex]?.levelId != undefined) {
                  customFunctions = [
                    { type: 'file-pdf', function: () => downloadStudentInterviewPDF(jobInterviews?.items[dataIndex].studentId, jobInterviews?.items[dataIndex].levelId) }
                  ]
                }

                return (
                  <TableHelperButtons
                    itemId={jobInterviews?.items[dataIndex]?.id!!}
                    itemParam={`${jobInterviews?.items[dataIndex]?.firstName!!} ${jobInterviews?.items[dataIndex]?.lastName!!}`}
                    functions={customFunctions}
                    links={[
                      { type: 'user', value: '/account/users/' + jobInterviews?.items[dataIndex]?.studentId!! },

                    ]}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false
            },
          }
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { JobInterviewsListModal }
