import { IVocabularyModel } from '../../models/responses/VocabularyModel'
import API from './Api'

class VocabularyApi {
  async GetVocabulary(id?: string, word?: string, language?: number, levelId?: number) {
    return await API.get<IVocabularyModel>('t/CompanyManager/Vocabularies/' + (word != '' && levelId != undefined ? `${word}/${language}/${levelId}` : id))
  }
}

export default new VocabularyApi()
