import { renewToken } from '../redux/AuthService'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'

export function Silent() {
  const dispatch = useDispatch()

  renewToken().then(user => {
   // dispatch(auth.actions.refreshToken(user.access_token))
  })
  return (
    <></>
  )
}
