/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { LoginInformationsListModal } from './_modals/LoginInformationsListModal'
import { Route, Routes } from 'react-router-dom'
import { JobInterviewsListModal } from './_modals/JobInterviewsListModal'
import { NativeRatingsListModal } from './_modals/NativeRatingsListModal'
import { StudentReportsListModal } from './_modals/StudentReportsListModal'

const ReportPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 mt-6'>
          <Routes>
            <Route path={'/loginInformations'} element={<>
              <PageTitle>Login Informations</PageTitle>
              <LoginInformationsListModal refresh={refresh} onClickItem={onClickItem} />
            </>}>
            </Route>
            <Route path={'/jobInterviews'} element={<>
              <PageTitle>Job Interviews</PageTitle>
              <JobInterviewsListModal refresh={refresh} onClickItem={onClickItem} />
            </>}>
            </Route>
            <Route path={'/nativeRatings'} element={<>
              <PageTitle>Native Ratings</PageTitle>
              <NativeRatingsListModal refresh={refresh} onClickItem={onClickItem} />
            </>}>
            </Route>
            <Route path={'/studentReports'} element={<>
              <PageTitle>Student Reports</PageTitle>
              <StudentReportsListModal refresh={refresh} onClickItem={onClickItem} />
            </>}>
            </Route>
          </Routes>
        </div>
      </div>
    </>
  )
}
const emptyBreadCrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
]

const ReportWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={emptyBreadCrumbs}>Reports</PageTitle>
      <ReportPage />
    </>
  )
}

export { ReportWrapper }
