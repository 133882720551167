import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { userInitValues } from '../../../../models/responses/UserModel'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { UserFormValidations } from '../../../validations/UserFormValidations'
import { useFormik } from 'formik'
import { IUserRequestModel } from '../../../../models/requests/UserRequestModel'
import { useNavigate } from "react-router-dom";
import { RoleModel } from '../../../../models/responses/RoleModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { useSelector, shallowEqual } from 'react-redux'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'


type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const UserFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([] as RoleModel[])
  const [doneId, setDoneId] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [complete, setComplete] = useState(false)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [selectedRole, setSelectedRole] = useState('')
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const formik = useFormik({
    initialValues: userInitValues,
    enableReinitialize: true,
    validationSchema: UserFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        if (user.companyId) {
          let model: IUserRequestModel = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            phoneNumber: values.phoneNumber,
            roles: roles.filter(i => i.isActive == true).map(i => i.name),
            claims: [],
            isActive: true
          }
          var permissions: string[] = [];
          
          model.roles = [selectedRole]

          model.claims = permissions.filter(onlyUnique).map(item => {
            var object: DictionaryModel = { type: "Permission", value: item };
            return object
          });

          model.claims.push({ type: "CompanyId", value: user.companyId })

          processUser(model);
        }
      }, 500)
    },
  })

  async function processUser(model: IUserRequestModel) {
    try {
      setLoading(true)
      var result = await UserApi.AddUser(model)
      if (result.data.hasError) {
        setErrors(result.data.errors)
      } else {
        setErrors([])
        formik.resetForm()
        handleClose()
        setDoneId(result.data);
      }
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function getRoles() {
    var response = await RoleApi.GetRoles()
    response.data.items.forEach(item => {
      item.isActive = false;
    })

    setRoles(response.data.items)
  }

  useEffect(() => {
    getRoles();
    if (doneId !== '') {
      setTimeout(() => navigate('/account/users/' + doneId), 2000);
    }
  }, [doneId, show]);

  const handleRoleOnClick = (roleId: string) => {
    var tempRoles = roles;
    var tempIndex = roles.findIndex(s => s.id == roleId);
    tempRoles[tempIndex].isActive = !tempRoles[tempIndex].isActive

    setSelectedRole(tempRoles[tempIndex].name)
    setRoles(tempRoles)
  }
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-750px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='fw-bolder'>{itemId === '' ? 'Add' : 'Edit'} User</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mh-500px  mx-5 my-7'>
            <div className='row'>
              <div className='col-12'>
                {
                  errors.length > 0 && <div className='alert alert-danger'>  <h4 className="alert-heading">Please fix the following issues and try again!</h4>
                    <p>
                      <ol>
                        {errors.map((item, key) => {
                          return (
                            <li key={key}>{item}</li>
                          )
                        })}
                      </ol>
                    </p>
                  </div>
                }
              </div>
              <div className='col-12 mb-10'>
                <div className='row'>
                  <label className='fs-5 fw-bolder form-label mb-2 required'>Roles</label>
                  <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                    {roles.map((role, index) => {
                      return (
                        <div key={index} className='col'>
                          <label className='form-check form-check-custom form-check-solid align-items-start me-10 mb-3'>
                            <input
                              {...formik.getFieldProps('roles')}
                              className='form-check-input me-3'
                              type='radio'
                              name='roles'
                              defaultChecked={role.isActive || false}
                              onChange={() =>
                                handleRoleOnClick(role.id)
                              }
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fs-5 mb-0'>{role.name}</span>
                            </span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className='row'>
                  <div className='col-6 mb-10'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>First Name</label>
                    <input
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      name='firstName'
                      className='form-control form-control-solid'
                      placeholder='First Name'
                    />
                  </div>
                  <div className='col-6 mb-10'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>Last Name</label>
                    <input
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      name='lastName'
                      className='form-control form-control-solid'
                      placeholder='Last Name'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 mb-10'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>E-mail</label>
                    <input
                      {...formik.getFieldProps('email')}
                      type='text'
                      name='email'
                      className='form-control form-control-solid'
                      placeholder='E-mail'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-6 mb-10'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>Phone</label>
                    <input
                      {...formik.getFieldProps('phoneNumber')}
                      type='text'
                      name='phoneNumber'
                      className='form-control form-control-solid'
                      placeholder='Phone'
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.phoneNumber}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>Password</label>
                    <input
                      {...formik.getFieldProps('password')}
                      type='password'
                      name='password'
                      className='form-control form-control-solid'
                      placeholder='Password'
                    />
                  </div>
                  <div className='col-6'>
                    <label className='fs-5 fw-bolder form-label mb-2 required'>Password Valid</label>
                    <input
                      {...formik.getFieldProps('passwordValid')}
                      type='password'
                      name='passwordValid'
                      className='form-control form-control-solid'
                      placeholder='Password Valid'
                    />
                  </div>
                  <div className='col-12'>
                    {formik.touched.passwordValid && formik.errors.passwordValid && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.passwordValid}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form >
    </Modal >
  )
}

export { UserFormModal }