import API from './Api'
import { IChangeLevelsRequestModel } from '../../models/requests/ChangeLevelsRequestModel'
import { IDeleteTrainerPlanRequestModel } from '../../models/requests/DeleteTrainerPlanRequestModel'
import { ITrainerModel, ITrainerSettingsModel, ITrainersModel } from '../../models/responses/TrainerModel'
import { IChangeTrainerPlanRequestModel } from '../../models/requests/ChangeTrainerPlanRequestModel'
import { IGetTrainerPlansRequestModel } from '../../models/requests/GetTrainerPlansRequestModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { ITrainerRequestModel } from '../../models/requests/TrainerRequestModel'

class TrainerApi {
    async GetTrainer(id: string) {
        return await API.get<ITrainerModel>('s/CompanyManager/Trainers/' + id)
    }

    async GetTrainerSettings(id: string) {
        return await API.get<ITrainerSettingsModel>('s/CompanyManager/Trainers/Settings/' + id)
    }

    async GetTrainerPlans(filter: IPaginationFilter, model: IGetTrainerPlansRequestModel) {
        return await API.post<PagedResponseModel<ITrainersModel[]>>(`s/CompanyManager/Trainers/${generateQueryParams(filter)}`, model)
    }

    async ChangeLevels(model: IChangeLevelsRequestModel) {
        return await API.put('s/CompanyManager/Trainers/ResponsibleLevel', model)
    }

    async ChangeTrainerPlan(model: IChangeTrainerPlanRequestModel) {
        return await API.patch('s/CompanyManager/Trainers/ChangePlan', model)
    }

    async UpdateTrainer(model: ITrainerRequestModel) {
        return await API.put('s/CompanyManager/Trainers/' + model.id, model)
    }

    async DeleteCallPlan(model: IDeleteTrainerPlanRequestModel) {
        return await API.patch<boolean>('s/CompanyManager/Trainers/DeletePlan', model)
    }
}

export default new TrainerApi()
