import { ITopicRequestModel } from '../../models/requests/TopicRequestModel'
import { ISetTopicListRequestModel } from '../../models/requests/SetTopicListRequestModel'

import { ITopicModel, ITopicsModel } from '../../models/responses/TopicModel'
import { ITopicListModel } from '../../models/responses/TopicListModel'

import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class TopicApi {

  async GetTopics(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ITopicsModel[]>>(`t/CompanyManager/Topics${generateQueryParams(filter)}`)
  }

  async GetTopic(id: string) {
    return await API.get<ITopicModel>('t/CompanyManager/Topics/' + id)
  }

  async AddTopic(model: ITopicRequestModel) {
    return await API.post('t/CompanyManager/Topics', model)
  }

  async SetTopicLists(model: ISetTopicListRequestModel) {
    return await API.post('t/CompanyManager/TopicLists/set', model)
  }

  async UpdateTopic(model: ITopicRequestModel) {
    return await API.put('t/CompanyManager/Topics/' + model.id, model)
  }

  async DeleteTopic(itemId: string) {
    return await API.delete<boolean>('t/CompanyManager/Topics/' + itemId)
  }

  async GetStudentTopicLists(companyId: string, studentId: string, levelId: number) {
    return await API.get<ITopicListModel>(`t/CompanyManager/TopicLists/company/${companyId}/${studentId}/${levelId}`)
  }
}

export default new TopicApi()
