/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { UserListModal } from './_modals/UserListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import { UserFormModal } from '../users/_modals/UserFormModal'
import UserApi from '../../../infrastructure/api/UserApi'
import { UserImportModal } from './_modals/UserImportModal'
import { useParams } from 'react-router-dom'

const UserPage: FC = () => {
  const { id } = useParams<any>()

  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showImport, setShowImport] = useState(false)
  const handleCloseImport = () => { setShowImport(false); setRefresh(refresh + 1) }
  const handleShowImport = () => setShowImport(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      case 'import':
        handleShowImport()
        break
      default:
        handleShowForm()
        break
    }
  }


  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            {/* <span className='card-label fw-bolder fs-3 mb-1'>Members Statistics</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a User'
          >
            {id == "student" &&
              <a
                href='#'
                onClick={() => onClickItem('', '', 'import')}
                className='btn btn-sm btn-light-info me-3'
              >
                <i className="fas fa-file-medical"></i>
                Import Students
              </a>
            }
            <a
              href='#'
              onClick={() => onClickItem('', '', 'create')}
              className='btn btn-sm btn-light-primary'
            >
              <i className="fas fa-plus"></i>
              Add User
            </a>
          </div>
        </div>

        <div className='card-body py-3'>
          <UserFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <UserImportModal itemId={itemId} show={showImport} handleClose={handleCloseImport} />

          <UserListModal refresh={refresh} onClickItem={onClickItem} />
        </div>
      </div>
    </>
  )
}
const emptyBreadCrumbs: Array<PageLink> = [
  {
    title: 'Accounts',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
]

const UserWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={emptyBreadCrumbs}>{intl.formatMessage({ id: 'Users' })}</PageTitle>
      <UserPage />
    </>
  )
}

export { UserWrapper }
