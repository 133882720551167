import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IMissionModel, IMissionsModel } from '../../models/responses/MissionModel'
import { IMissionRequestModel } from '../../models/requests/MissionRequestModel'

class MissionApi {

  async GetMissions(filter: IPaginationFilter, companyId: string) {
    return await API.get<PagedResponseModel<IMissionModel[]>>(`e/CompanyManager/Missions${generateQueryParams(filter)}&companyId=${companyId}`)
  }

  async GetMission(id: string, companyId: string) {
    return await API.get<IMissionModel>('e/CompanyManager/Missions/' + id + `?companyId=${companyId}`)
  }

  async GetStudentMissions(userId: string, levelId: number, companyId: string) {
    return await API.get<IMissionsModel>(`e/CompanyManager/Missions/student/${userId}/${levelId}` + `?companyId=${companyId}`)
  }

  async AddMission(model: IMissionRequestModel) {
    return await API.post('e/CompanyManager/Missions', model)
  }

  async UpdateMission(model: IMissionRequestModel) {
    return await API.put('e/CompanyManager/Missions/' + model.id, model)
  }

  async DeleteMission(itemId: string, companyId: string) {
    return await API.delete<boolean>('e/CompanyManager/Missions/' + itemId + `?companyId=${companyId}`)
  }
  async DeleteStudentMission(itemId: string, userId: string, companyId: string) {
    return await API.delete<boolean>(`e/CompanyManager/Missions/${itemId}/${userId}?companyId=${companyId}`)
  }
}

export default new MissionApi()
