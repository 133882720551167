import { FC } from "react"
import VocabularyApi from "../../../../infrastructure/api/VocabularyApi"
import { IPartofSpeechModel } from "../../../../models/responses/PartofSpeechModel"
import { ITopicVocabularyFormModel } from "../../../models/TopicVocabularyFormModel"
import { IVocabularyDetailFormModel } from "../../../models/VocabularyDetailFormModel"
import { VocabularyDetails } from "../../vocabularies/components/VocabularyDetails"

type Props = {
    vocabularies: ITopicVocabularyFormModel[]
    setVocabularies: (vocabularies: ITopicVocabularyFormModel[], index?: number) => void
    selectedLevel: number
    selectedLanguage: number
    partofSpeechs: IPartofSpeechModel[]
    getInput: (name: string) => HTMLInputElement
}

export const Vocabularies: FC<Props> = ({ vocabularies, setVocabularies, selectedLevel, selectedLanguage, partofSpeechs, getInput }) => {
    const getAvailableDetails = async (word: string, index: number) => {
        var responseDetails = await VocabularyApi.GetVocabulary(undefined, word, selectedLanguage, selectedLevel)
        var newVocabularies = vocabularies!!
        if (newVocabularies[index!!]) {
            newVocabularies[index!!].availableDetails = []
            if (responseDetails.data != undefined && responseDetails.data.toString() !== '') {
                responseDetails.data.vocabularyDetails.filter(i => i.topicId == null).map((available) => {
                    newVocabularies[index!!].availableDetails?.push({
                        id: available.id,
                        example: available.example,
                        definition: available.definition,
                        partofSpeech: { label: available.partofSpeech, value: available.partofSpeech }
                    })
                })
            }
            setVocabularies([...newVocabularies])
        }
    }

    const setDetails = (details: IVocabularyDetailFormModel[], index?: number) => {
        var newVocabularies = vocabularies!!
        if (newVocabularies[index!!]) {
            newVocabularies[index!!].details = details
        }
        setVocabularies([...newVocabularies])
    }

    const addNewVocabulary = () => {
        var newVocabularies = vocabularies!!
        if (!newVocabularies) newVocabularies = []
        newVocabularies.push({ word: '', level: selectedLevel, language: selectedLanguage, availableDetails: [], details: [] })
        setVocabularies([...newVocabularies])
    }

    const findWord = (index: number) => {
        const input: HTMLInputElement = getInput(`word${index}`)
        let checkWords = vocabularies.filter(i => i.word == input.value);
        if (checkWords.length!! == 1) {
            getAvailableDetails(input.value, index)
        } else {
            alert("This word already added.")
        }
    }

    const handleChangeWord = (e: any, index: number) => {
        var newVocabularies = vocabularies!!
        if (newVocabularies[index]) {
            newVocabularies[index].word = e.target.value
            newVocabularies[index].availableDetails = []
            newVocabularies[index].details = []
        }
        setVocabularies([...newVocabularies])
    }

    return (
        <>
            <h2>Vocabularies</h2>
            <hr />
            {vocabularies.map((item, index) => {
                return (
                    <div key={index} className="card card-custom bg-light-success gutter-b mb-3">
                        <div className="card-body pt-2">
                            <div className='row mt-8'>
                                <div className='col-lg-5'>
                                    <label className='form-label fw-bolder required'>Word</label>
                                    <div className="input-group">
                                        <input
                                            type='text'
                                            name={`word${index}`}
                                            defaultValue={item.word}
                                            onChange={(event) => handleChangeWord(event, index)}
                                            className='form-control'
                                            placeholder='Entry..'
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" onClick={() => findWord(index)} type="button">Find!</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <h3 className="card-title font-weight-bolder text-success">Educall's Details</h3>
                                    {(vocabularies[index] && vocabularies[index].availableDetails && vocabularies[index].availableDetails?.length!! > 0) ? vocabularies[index].availableDetails?.map((detail, indexDetail) => {
                                        return (
                                            <div key={indexDetail} className="d-flex align-items-center mb-5">
                                                <div className="d-flex flex-column font-weight-bold">
                                                    <span><b>{indexDetail + 1}. ({detail.partofSpeech?.value}) Example:</b> {detail.example}</span>
                                                    <span className="text-muted"><b>Definition:</b> {detail.definition}</span>
                                                </div>
                                            </div>
                                        )
                                    }) : <span>Not have details!</span>}
                                </div>
                                <div className='col-lg-12 mt-5'>
                                    {(vocabularies[index] && vocabularies[index].details) &&
                                        <VocabularyDetails
                                            details={vocabularies!![index].details!!}
                                            setDetails={setDetails}
                                            levelsData={[]}
                                            partofSpeechsData={partofSpeechs}
                                            languageId={selectedLanguage}
                                            wordIndex={index}
                                            isForTopic={true} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div style={{ textAlign: 'right' }}>
                <button type='button' onClick={() => addNewVocabulary()} className='btn btn-info'>
                    <i className='fa fa-plus'></i> Add Vocabulary
                </button>
            </div>
        </>
    )
}