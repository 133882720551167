import { IVocabularyModel } from "./VocabularyModel"

export interface ITopicsModel {
  id: string
  title: string
  link: string
  comment: string
  order: number
  image: string
  video: string
  content: string
  isGroupTopic: boolean
  levelId: number
  type: number
  languageId: number
  companyId: string
  topicUnitIds: number[]
  vocabularyIds: string[]
  talkingPoints: string[]
}

const topicsInitValues: ITopicsModel = {
  id: '',
  title: '',
  link: '',
  comment: '',
  order: 0,
  image: '',
  video: '',
  content: '',
  isGroupTopic: true,
  levelId: 0,
  type: 0,
  languageId: 0,
  companyId: '',
  topicUnitIds: [],
  vocabularyIds: [],
  talkingPoints: [],
}

export interface ITopicModel {
  id: string
  title: string
  link: string
  comment: string
  order: number
  image: string
  video: string
  content: string
  isGroupTopic: boolean
  levelId: number
  type: number
  languageId: number
  companyId: string
  topicUnitIds: number[]
  vocabularies: IVocabularyModel[]
  talkingPoints: string[]
}

const topicInitValues: ITopicModel = {
  id: '',
  title: '',
  link: '',
  comment: '',
  order: 0,
  image: '',
  video: '',
  content: '',
  isGroupTopic: true,
  levelId: 0,
  type: 0,
  languageId: 0,
  companyId: '',
  topicUnitIds: [],
  vocabularies: [],
  talkingPoints: [],
}


export { topicInitValues, topicsInitValues }
