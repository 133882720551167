import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { IMissionModel } from '../../../../models/responses/MissionModel'
import MissionApi from '../../../../infrastructure/api/MissionApi'
import { MissionTypeEnum } from '../../../../enums/MissionTypeEnum'
import { EdugameEnum } from '../../../../enums/EdugameEnum'
import * as level from '../../../pages/levels/redux/LevelRedux'
import * as exam from '../../exams/redux/ExamRedux'

import { SelectModel } from '../../../models/SelectModel'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import ExamApi from '../../../../infrastructure/api/ExamApi'
import { UserModel } from '../../../modules/auth/models/UserModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}


const MissionListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [missions, setMissions] = useState<PagedResponseModel<IMissionModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('title_asc')
  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const examDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examDictionary, shallowEqual) as SelectModel[]
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()

  async function init() {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    if (examDictionary.length < 1) {
      var responseExams = await ExamApi.GetExamDictionary()
      var dataExams: SelectModel[] = [...responseExams.data.map(d => ({
        value: d.id.toString(),
        label: d.title
      }))]
      dispatch(exam.actions.setExamDictionary(dataExams));
    }

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    if (user.companyId) {
      var response = await MissionApi.GetMissions(paginationFilter, user.companyId)
      setMissions(response.data)
    }
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Missions'
        data={missions?.items}
        currentTotalRecords={missions?.totalRecords!!}
        currentPageNumber={missions?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: 'title',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Level',
            name: 'levelId',
            options: {
              customBodyRender: (value) => {
                return <b>{levelDictionary.find(i => i.value == value)?.label}</b>
              },
            },
          },
          {
            label: 'Mission',
            name: 'type',
            options: {
              customBodyRender: (value) => {
                return <b>{MissionTypeEnum[value]}</b>
              },
            },
          },
          {
            label: 'Param',
            name: 'param',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <b>{missions?.items[dataIndex].type == MissionTypeEnum.Game ?
                  EdugameEnum[parseInt(missions?.items[dataIndex].param)] :
                  (missions?.items[dataIndex].type == MissionTypeEnum.Exam ?
                    examDictionary.find(i => i.value == missions?.items[dataIndex].param)?.label : "-")}</b>
              },
            },
          },
          {
            label: 'Required',
            name: 'requiredNumber',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Condition',
            name: 'conditionScore',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={missions?.items[dataIndex]?.id!!}
                    itemParam={missions?.items[dataIndex]?.title!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { MissionListModal }
