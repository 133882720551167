import { FC } from "react"

type Props = {
    talkingPoints?: string[]
    setTalkingPoints: (talkingPoints: string[], index?: number) => void
}
export const TalkingPoints: FC<Props> = ({ talkingPoints, setTalkingPoints }) => {

    const addNewTalkingPoint = () => {
        var newTalkingPoints = talkingPoints || []
        newTalkingPoints.push('')
        setTalkingPoints([...newTalkingPoints])
    }
    const handleChangeTalkingPoints = (e: any, index: number) => {
        var newTalkingPoints = talkingPoints!!
        if (newTalkingPoints[index] != undefined) {
            newTalkingPoints[index] = e.target.value
        }
        setTalkingPoints([...newTalkingPoints])
    }

    return (
        <>
            <h4 className='mt-8'>Talking Points</h4>
            <hr />
            {talkingPoints?.map((item, index) => {
                return (
                    <div className='col-lg-12' key={index}>
                        <input
                            type='text'
                            onChange={(event) => handleChangeTalkingPoints(event, index)}
                            defaultValue={item}
                            className='form-control form-control-solid mb-6'
                            placeholder='Entry..'
                        />
                    </div>
                )
            })}
            <div style={{ textAlign: 'right' }}>
                <button type='button' onClick={() => addNewTalkingPoint()} className='btn btn-success btn-sm mb-6'>
                    <i className='fa fa-plus'></i> Add Talking Point
                </button>
            </div>
        </>
    )

}