import { SelectModel } from '../../app/models/SelectModel'
import { RoleRequestModel } from '../../models/requests/RoleRequestModel'
import { IRoleDictionaryModel } from '../../models/responses/RoleDictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { RoleModel } from '../../models/responses/RoleModel'
import API from './Api'

class RoleApi {
  async GetRoles() {
    return await API.get<PagedResponseModel<RoleModel[]>>('i/CompanyManager/Roles')
  }

  async GetRoleDictionary() {
    return await API.get<IRoleDictionaryModel[]>('i/CompanyManager/Roles/dictionary')
  }
}

export default new RoleApi()
