import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { CompanyFormModal } from './_modals/CompanyFormModal'
import { CompanyListModal } from './_modals/CompanyListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import CompanyApi from '../../../infrastructure/api/CompanyApi'

const CompanyPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteCompany(itemId: string) {
    var response = await CompanyApi.DeleteCompany(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a category'
          >
            <a
              href='#'
              onClick={() => onClickItem('', '', 'create')}
              className='btn btn-sm btn-light-primary'
            >
              <i className="fas fa-plus"></i>
              Add Section
            </a>
          </div>
        </div>

        <div className='card-body py-3'>
          <CompanyListModal refresh={refresh} onClickItem={onClickItem} />
          <CompanyFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Section'
            confirm={deleteCompany}
          />
        </div>
      </div>
    </>
  )
}

const CompanyWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Section</PageTitle>
      <CompanyPage />
    </>
  )
}

export { CompanyWrapper }
