import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Overview } from './components/Overview'
import { Settings } from './components/settings/Settings'
import { Topics } from './components/Topics'
import { Exams } from './components/Exams'

import { CallPlans } from './components/CallPlans'
import { TrainerCallPlans } from './components/TrainerCallPlans'

import { AccountHeader } from './AccountHeader'
import UserApi from '../../../infrastructure/api/UserApi'
import { useParams } from 'react-router'
import { connect, useDispatch, ConnectedProps, useSelector, shallowEqual } from 'react-redux'
import * as account from './redux/AccountRedux'
import { RootState } from '../../../setup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { IUserModel } from '../../../models/responses/UserModel'
import { RoleEnum } from '../../../enums/RoleEnum'
import { Missions } from './components/Missions'
import { Schedules } from './components/Schedules'

const mapState = (state: RootState) => ({ account: state.account })
const connector = connect(mapState, account.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AccountPage: React.FC<PropsFromRedux> = (props) => {
  const updateSelectedUser: boolean = useSelector<RootState>(({ account }) => account.updateSelectedUser, shallowEqual) as boolean

  const dispatch = useDispatch()
  const { id } = useParams<any>()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Users',
      path: `#`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [count, setCount] = useState<number>(0)
  async function getUser(id: string) {
    var response = await UserApi.GetUser(id!!)
    var user = response.data;
    const currentAvatar = user.details?.find(i => i.type == "Photo")?.value!!;
    user.photo = (currentAvatar && currentAvatar != "" ? `${process.env.REACT_APP_CDN_URL}images/avatar/${currentAvatar}` : toAbsoluteUrl('/media/avatars/blank.png'))

    dispatch(props.setSelectedUser(user))
    dispatch(props.setRoles(user.roles!!))
  }

  useEffect(() => {
    if (updateSelectedUser || count == 0) {
      setCount(count + 1);
      getUser(id!!);
      dispatch(props.updateSelectedUser(false))
    }
  }, [updateSelectedUser])

  return (
    <>
      <AccountHeader />
      <Routes>
        <Route path='overview' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
          <Overview />
        </>}>
        </Route>
        <Route path='settings' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
          <Settings />
        </>}>
        </Route>
        <Route path='call-plans' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Call Plan</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Student) ? <CallPlans /> : (user.roles?.some(s => s == RoleEnum.Trainer) ? <TrainerCallPlans /> : <></>))}
        </>}>
        </Route>
        <Route path='schedules' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Schedules</PageTitle>
          <Schedules />
        </>}>
        </Route>
        <Route path='missions' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Missions</PageTitle>
          <Missions />
        </>}>
        </Route>
        <Route path='topics' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Topics</PageTitle>
          <Topics />
        </>}>
        </Route>
        <Route path='exams' element={<>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Exams</PageTitle>
          <Exams />
        </>}>
        </Route>
        <Route path='*' element={<Navigate to='overview' />} />
      </Routes>
    </>
  )
}

export default connector(AccountPage)