import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { capitalizeFirstLetter } from '../../../helpers/StringHelper'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { useParams } from 'react-router-dom'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const UserListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { id } = useParams<any>()

  const [users, setUsers] = useState<PagedResponseModel<IUserModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')


  const paginationFilter: IPaginationFilter = {
    page: pageNumber,
    size: pageSize,
    orderBy: sort,
    search: searchText
  }

  useEffect(() => {
    getUsers(id!);
  }, [id, refresh, pageNumber, pageSize, searchText, sort])

  async function getUsers(roleName: string) {
    if (user.companyId) {
      var companyIds = await CompanyApi.GetSubCompaniesDictionary(user.companyId);

      var filters: DictionaryModel[] = [{ type: "Role", value: roleName }, { type: "CompanyIds", value: companyIds.data.map(i => i.id).join(",") }]
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      setUsers(response.data)
    }
  }

  return (
    <>
      <GenericTable
        title={capitalizeFirstLetter(id!.toString()) + 's'}
        data={users?.items}
        currentTotalRecords={users?.totalRecords!!}
        currentPageNumber={users?.pageNumber!!}
        columns={[
          {
            label: "#", name: "#", options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            }
          },
          {
            label: "FullName", name: "firstName", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{users?.items[dataIndex].firstName} {users?.items[dataIndex].lastName}</b>
                )
              },
            },
          },
          { label: "Email", name: "email" },
          { label: "Phone", name: "phoneNumber" },
          {
            label: "#", name: "Id", options: {
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={users?.items[dataIndex]?.id!!}
                    itemParam={`${users?.items[dataIndex]?.firstName!!} ${users?.items[dataIndex]?.lastName!!}`}
                    links={[
                      { type: 'user', value: '/account/users/' + users?.items[dataIndex]?.id!! }
                    ]}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false
            },
          }
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { UserListModal }
