import { ILevelModel } from '../../models/responses/LevelModel'
import API from './Api'

class LevelApi {
  async GetLevelDictionary() {
    return await API.get<ILevelModel[]>(`e/CompanyManager/Levels/dictionary`)
  }
}

export default new LevelApi()
