export interface SelectModel {
    label?: string,
    value: string
}

const selectInitValues: SelectModel = {
    value: "",
    label: "--SELECT--"
}

export { selectInitValues }