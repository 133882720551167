import { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import { IChangeTrainerPlanRequestModel, changeTrainerPlanInitValues } from '../../../../models/requests/ChangeTrainerPlanRequestModel'
import { StudentPlanFormValidations } from '../../../validations/StudentPlanFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import moment from 'moment'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { useParams } from 'react-router-dom'
import { ITrainerPlanModel, trainerPlanInitValues } from '../../../../models/responses/TrainerModel'
import { initOnLoad } from 'apexcharts'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
  plans: ITrainerPlanModel[]
}

const TrainerCallPlanFormModal: FC<Props> = ({ itemId, show, handleClose, handleSuccess, plans }) => {
  const { id } = useParams<any>()

  const [loading, setLoading] = useState(false)

  const [plan, setPlan] = useState<ITrainerPlanModel>(trainerPlanInitValues)

  const [complete, setComplete] = useState(false)

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };
  const dayList = EnumToList(DayOfWeekEnum);
  const [selectedDay, setSelectedDay] = useState<SelectModel>(defaultSelect)

  const formik = useFormik<ITrainerPlanModel>({
    initialValues: plan,
    enableReinitialize: true,
    validationSchema: StudentPlanFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IChangeTrainerPlanRequestModel = {
          id: itemId == '' ? undefined : itemId,
          trainerId: id!!,
          dayOfWeek: parseInt(selectedDay.value),
          startDate: values.startDate,
          duration: values.duration
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IChangeTrainerPlanRequestModel) {
    try {
      var result = undefined
      result = await TrainerApi.ChangeTrainerPlan(model)
      formik.resetForm()
      handleClose()
      handleSuccess()
    } catch (error: any) {
      setLoading(false)
    }
    setLoading(false)
  }

  const init = async () => {
    let plan = plans.find(i => i.id == itemId);
    if (plan != null) {
      setPlan(plan)
      handleChangeDay(plan?.dayOfWeek ? dayList.find(i => i.value == plan?.dayOfWeek.toString()) : defaultSelect)
    }
  }

  useEffect(() => {
    formik.resetForm()
    setPlan(trainerPlanInitValues)
    init()
  }, [itemId, show])

  const handleChangeDay = (e: any) => {
    setSelectedDay(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Trainer Call Plan</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Day of Week</label>
                <Select
                  {...formik.getFieldProps('dayOfWeek')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeDay(event)}
                  value={selectedDay}
                  options={dayList}
                />
                <FormikValidationError touched={formik.touched.dayOfWeek} error={formik.errors.dayOfWeek} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Start Time</label>
                <input
                  {...formik.getFieldProps('startDate')}
                  type='time'
                  name='startDate'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.startDate} error={formik.errors.startDate} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Minutes</label>
                <input
                  {...formik.getFieldProps('duration')}
                  type='number'
                  name='duration'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.duration} error={formik.errors.duration} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { TrainerCallPlanFormModal }
