export interface ICompanyModel {
  id: string
  name: string
  title: string
  phone: string
  website: string
  logo: string
  background: string
  parentCompanyId: string
  companyType: number
  subCompanies: ICompanyModel[]
}

const companyInitValues: ICompanyModel = {
  id: '',
  name: '',
  title: '',
  phone: '',
  website: '',
  logo: '',
  background: '',
  parentCompanyId: '',
  companyType: 0,
  subCompanies: []
}

export {companyInitValues}
