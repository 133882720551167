import * as Yup from 'yup'

const ResultFormValidations = () => {
  return Yup.object().shape({
    evaluation: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Name is required'),
  })
}

export { ResultFormValidations }
