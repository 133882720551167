/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RoleEnum } from '../../../../enums/RoleEnum'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import { KTSVG } from '../../../../_metronic/helpers'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import { IStudentPlanModel } from '../../../../models/responses/StudentModel'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import DualListBox from 'react-dual-listbox';
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { SelectModel } from '../../../models/SelectModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { IChangeStudentBlockedTrainersRequestModel } from '../../../../models/requests/ChangeStudentBlockedTrainersRequestModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { DeleteModal } from '../../../components/modals/DeleteModal'
import { StudentCallPlanFormModal } from '../../../pages/students/_modals/StudentCallPlanFormModal'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import { UserModel } from '../../auth/models/UserModel'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'

export function CallPlans() {

  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const { id } = useParams<any>()
  const userAuth: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const [plans, setPlans] = useState<IStudentPlanModel[]>([])
  const [trainers, setTrainers] = useState<SelectModel[]>([])

  const [selectedBlocked, setSelectedBlocks] = useState<string[]>([])

  const [trainerInfos, setTrainerInfos] = useState<IUserDictionaryModel[]>([])


  const init = (async () => {
    getTrainers();
    var responseStudent = await StudentApi.GetStudent(id!!)
    if (responseStudent.data.studentPlans != null) {
      setPlans(responseStudent.data.studentPlans)

      var responseUsers = await UserApi.GetUserDictionaryIds(responseStudent.data.studentPlans.map(i => i.trainerId!!))
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setTrainerInfos(dataUsers)

    }
    if (responseStudent.data.blockedTrainerIds != null)
      setSelectedBlocks(responseStudent.data.blockedTrainerIds)
  });

  async function deleteCallPlan(planId: string) {
    var response = await StudentApi.DeleteCallPlan({ studentId: id!!, studentPlanId: planId })
    init();
  }


  async function getTrainers() {

    if (userAuth.companyId) {
      var companyIds = await CompanyApi.GetSubCompaniesDictionary(userAuth.companyId);

      var filters: DictionaryModel[] = [{ type: "Role", value: RoleEnum.Trainer.toString(), }, { type: "CompanyIds", value: companyIds.data.map(i => i.id).join(",") }]
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 99999,
        orderBy: 'firstName_asc',
        search: ''
      }
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var dataTrainers: SelectModel[] = [...response.data.items.map(d => ({
        value: d.id.toString(),
        label: `${d.firstName} ${d.lastName} - ${d.email}`
      }))]
      setTrainers(dataTrainers)

    }
  }

  async function saveBlockedList() {
    try {

      var model: IChangeStudentBlockedTrainersRequestModel = {
        studentId: id!!,
        trainerIds: selectedBlocked
      }
      await StudentApi.BlockedTrainer(model)
      setComplete(true)
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  useEffect(() => {
    if (!user.roles?.some(s => s == RoleEnum.Student || s == RoleEnum.Trainer)) {
      // document.location.href = "/error/404"
    } else {
      init()
    }
  }, [user])

  const handleOnChangeBlockedList = (selected: string[]) => {
    setSelectedBlocks(selected.filter(i => trainers.some(x => x.value.toString() == i)))
  }
  return (
    <>

      <div className='row mb-7'>
        <div className='col-lg-12'>
          <StudentCallPlanFormModal trainers={trainers} itemId={id!!} show={showForm} handleClose={handleCloseForm} handleSuccess={init} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Student Call Plan'
            confirm={deleteCallPlan}
          />
          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-2 card-rounded h-200px w-100 bg-primary`}>
                <div className="d-flex flex-stack">
                  <h3 className="m-0 mt-10 text-white fw-bolder fs-3">Student Plans</h3>
                  <div className="ms-1">
                    <button type="button"
                      onClick={() => onClickItem('', '', 'create')}
                      className="btn btn-dark btn-sm btn-color-white btn-active-white btn-active-color-primary border-0 me-n3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <i className='fa fa-plus'></i> Add Plan
                    </button>
                  </div>
                </div>
              </div>

              <div className='row'>
                {plans.filter(i => i.type == ScheduleTypeEnum.Planned).length > 0 &&
                  <div className='col'>
                    <div
                      className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                      style={{ marginTop: '-100px' }}>
                      <h3 className='mb-5'>Planned</h3>
                      {plans.filter(i => i.type == ScheduleTypeEnum.Planned).map((item, index) => {
                        return (
                          <div className={'d-flex align-items-center ' + (index != plans.length - 1 ? 'mb-6' : '')} key={index}>
                            <div className='symbol symbol-45px w-40px me-5' onClick={() => onClickItem(item.id!!, `${DayOfWeekEnum[item.dayOfWeek]}, ${item.startDate} - ${item.endDate}`, 'delete')}
                            >
                              <button className='btn btn-sm btn-icon btn-color-danger btn-active-white symbol-label'>
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                              </button>
                            </div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                              <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                  {DayOfWeekEnum[item.dayOfWeek]}
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>{item.startDate} - {item.endDate} ({item.duration} Minutes)</div>
                                <span className='fs-5 text-gray-800 text-hover-primary'>
                                  Trainer: <span className=' fw-bolder'>{item.trainerId != undefined ? `${trainerInfos.find(s => s.id == item.trainerId)?.firstName} ${trainerInfos.find(s => s.id == item.trainerId)?.lastName}` : 'N/A'}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                }
                {plans.filter(i => i.type != ScheduleTypeEnum.Planned).length > 0 &&
                  <div className='col'>
                    <div
                      className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                      style={{ marginTop: '-100px' }}>
                      <h3 className='mb-5'>One Time or Groups</h3>
                      {plans.filter(i => i.type != ScheduleTypeEnum.Planned).map((item, index) => {
                        return (
                          <div className={'d-flex align-items-center ' + (index != plans.length - 1 ? 'mb-6' : '')} key={index}>
                            <div className='symbol symbol-45px w-40px me-5' onClick={() => onClickItem(item.id!!, `${DayOfWeekEnum[item.dayOfWeek]}, ${item.startDate} - ${item.endDate}`, 'delete')}
                            >
                              <button className='btn btn-sm btn-icon btn-color-danger btn-active-white symbol-label'>
                                <KTSVG path='/media/icons/duotune/General/gen027.svg' className='svg-icon-1' />
                              </button>
                            </div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                              <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                  {DayOfWeekEnum[item.dayOfWeek]}
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>{item.startDate} - {item.endDate} ({item.duration} Minutes)</div>
                                <span className='fs-5 text-gray-800 text-hover-primary'>
                                  Trainer: <span className=' fw-bolder'>{item.trainerId != undefined ? `${trainerInfos.find(s => s.id == item.trainerId)?.firstName} ${trainerInfos.find(s => s.id == item.trainerId)?.lastName}` : 'N/A'}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='position-relative z-index-1'>
            <div className='form-control px-6 py-9 '>
              <div className='d-flex flex-stack'>
                <h3 className='text-success'>Allowed Trainers</h3>
                <h3 className='text-end text-danger'>Blocked Trainers</h3>
              </div>
              <DualListBox
                canFilter
                options={trainers}
                selected={selectedBlocked}
                onChange={handleOnChangeBlockedList}
              />
              <div className='text-end mt-5'>
                <SaveChangesButton
                  loading={loading}
                  setLoading={setLoading}
                  complete={complete}
                  setComplete={setComplete}
                  valid={true}
                  submit={true}
                  setSubmit={saveBlockedList} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
