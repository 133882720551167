import { FC } from 'react'
type Props = {
    touched?: boolean,
    error?: string,
}

const FormikValidationError: FC<Props> = ({ touched, error }) => {
    return (
        <>
            {touched && error && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>*{error}</div>
                </div>
            )}
        </>
    )
}

export { FormikValidationError }
