import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SelectModel } from '../../../models/SelectModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetExamDictionary: '[Set Exam Dictionary] Action',
    SetExamTypeDictionary: '[Set Exam Type Dictionary] Action'
}

export interface IExamState {
    examDictionary?: SelectModel[],
    examTypeDictionary?: SelectModel[],

}

const initialExamState: IExamState = {
    examDictionary: [],
    examTypeDictionary: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-exams',
        whitelist: ['examTypeDictionary', 'examDictionary'],
        blacklist: [],
    },
    (state: IExamState = initialExamState, action: ActionWithPayload<IExamState>) => {
        switch (action.type) {
            case actionTypes.SetExamDictionary: {
                const examDictionary = action.payload?.examDictionary
                return { ...state, examDictionary }
            }
            case actionTypes.SetExamTypeDictionary: {
                const examTypeDictionary = action.payload?.examTypeDictionary
                return { ...state, examTypeDictionary }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setExamDictionary: (examDictionary: Array<SelectModel>) => ({ type: actionTypes.SetExamDictionary, payload: { examDictionary } }),
    setExamTypeDictionary: (examTypeDictionary: Array<SelectModel>) => ({ type: actionTypes.SetExamTypeDictionary, payload: { examTypeDictionary } }),
}
