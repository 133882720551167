import { FC, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import UserLogApi from '../../../../infrastructure/api/UserLogApi'
import { IUserLogModel } from '../../../../models/responses/UserLogModel'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { downloadFile } from '../../../helpers/FileHelper'
import { RoleEnum } from '../../../../enums/RoleEnum'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const LoginInformationsListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [companyIds, setCompanyIds] = useState('')
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const excelJS = require("exceljs");


  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getUsers(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }



  const [users, setUsers] = useState<PagedResponseModel<IUserLogModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')

  async function init() {
    console.log(searchText)
    if (user.companyId && companyIds == '') {
      var companies = await CompanyApi.GetSubCompaniesDictionary(user.companyId);
      var dataParentCompanies: SelectModel[] = [
        ...companies.data.map((d) => ({
          value: d.id.toString(),
          label: d.name,
        })),
      ]
      setCompanies(dataParentCompanies)
      setCompanyIds(companies.data.map(i => i.id).join(","))
    }
    getUsers(start, end)
  }

  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }


  useEffect(() => {
    init();
  }, [user.companyId, refresh, pageNumber, pageSize, searchText, sort])

  async function getUsers(start: moment.Moment, end: moment.Moment) {


    if (user.companyId && selectedCompany?.value) {
      var companiesIds = ""
      var companies = await CompanyApi.GetSubCompaniesDictionary(selectedCompany.value);
      companiesIds = companies.data.map(i => i.id).join(",")

      var startTime = start.format("YYYY-MM-DDT00:00:00");
      var endTime = end.format("YYYY-MM-DDT23:59:59");

      var filters: DictionaryModel[] = [
        { type: "StartTime", value: startTime },
        { type: "EndTime", value: endTime },
        { type: "Role", value: RoleEnum.Student.toLocaleLowerCase() },
        { type: "CompanyIds", value: companiesIds },
        { type: "Event", value: "1000" }
      ]
      const paginationFilter: IPaginationFilter = {
        page: pageNumber,
        size: pageSize,
        orderBy: sort,
        search: searchText
      }

      var response = await UserLogApi.GetUserLogsByFilter(paginationFilter, filters)
      response.data.items.forEach(i => i.loginNumber = i.logs.filter(i => i.event == "1000").length)
      setUsers(response.data)
    }
  }


  async function getReports(start: moment.Moment, end: moment.Moment, companyId: string) {
    var startTime = start.format("YYYY-MM-DDT00:00:00");
    var endTime = end.format("YYYY-MM-DDT23:59:59");

    var companiesIds = ""
    var selectedCompanies = await CompanyApi.GetSubCompaniesDictionary(companyId);
    companiesIds = selectedCompanies.data.map(i => i.id).join(",")

    var filters: DictionaryModel[] = [
      { type: "StartTime", value: startTime },
      { type: "EndTime", value: endTime },
      { type: "Role", value: RoleEnum.Student.toLocaleLowerCase() },
      { type: "CompanyIds", value: companiesIds },
      { type: "Event", value: "1000" }

    ]
    const paginationFilter: IPaginationFilter = {
      page: 1,
      size: users?.totalRecords || 9999,
      orderBy: sort,
      search: searchText
    }

    var response = await UserLogApi.GetUserLogsByFilter(paginationFilter, filters)
    response.data.items.forEach(i => i.loginNumber = i.logs.filter(i => i.event == "1000").length)


    var fields = [
      { key: 'firstName', header: 'First Name', width: 15 },
      { key: 'lastName', header: 'Last Name', width: 15 },
      { key: 'email', header: 'E-mail', width: 25 },
      { key: 'phoneNumber', header: 'Phone Number', width: 15 },
      { key: 'loginNumber', header: 'Logins' }
    ];

    const workbook = new excelJS.Workbook();
    const worksheet = workbook.addWorksheet("Educall Student Logins");

    worksheet.columns = fields
    worksheet.getRow(1).eachCell((cell: any) => {
      cell.font = { bold: true };
    });
    worksheet.addRows(response.data.items);
    const buffer = await workbook.xlsx.writeBuffer();

    downloadFile(buffer, `Educall Student Logins - (${start.format("YYYY-MM-DD")} _ ${end.format("YYYY-MM-DD")}) _ ${moment().format("YYYY-MM-DD-HH-mm-ss")}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
    setComplete(true)

  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder'>Company Section</label>
          <Select
            styles={customSelectStyles(true)}
            menuPortalTarget={document.body}
            onChange={(event) => handleChangeCompany(event)}
            value={selectedCompany}
            options={companies}
          />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder'>Date Range</label>
          <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            style={{
              darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
            }}
            end={end}
            local={local}
            applyCallback={applyCallback}
          >
            <input
              id="formControlsTextB"
              className='form-control form-control-solid'
              value={`${start.format(local.format)} - ${end.format(local.format)}`}
              type="text"
            />
          </DateTimeRangeContainer>
        </div>
      </div>
      <GenericTable
        title={"Student Login Informations"}
        data={users?.items}
        currentTotalRecords={users?.totalRecords!!}
        currentPageNumber={users?.pageNumber!!}
        columns={[
          {
            label: "#", name: "#", options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
              download: false,
              print: false
            }
          },
          {
            label: "FullName", name: "firstName", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{users?.items[dataIndex].firstName} {users?.items[dataIndex].lastName}</b>
                )
              },
            },
          },
          { label: "Email", name: "email" },
          { label: "Phone", name: "phoneNumber" },
          {
            label: "Logins", name: "loginNumber", options: {
              sort: false,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='badge badge-lg badge-circle badge-light'>{users?.items[dataIndex].loginNumber}</div>
                )
              },
            },
          }, {
            label: "#", name: "Id", options: {
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={users?.items[dataIndex]?.userId!!}
                    itemParam={`${users?.items[dataIndex]?.firstName!!} ${users?.items[dataIndex]?.lastName!!}`}
                    links={[
                      { type: 'user', value: '/account/users/' + users?.items[dataIndex]?.userId!! }
                    ]}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              download: false,
              print: false,
              filter: false,
              sort: false,
              viewColumns: false
            },
          }
        ]}
        customOptions={{
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
        size={pageSize}
        Content={() => {
          return (
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={true}
              submit={true}
              customClasses={!loading ? "btn-sm bg-transparent btn-outline-primary btn-icon btn-active-light-dark btn-circle btn-custom" : "btn-sm btn-secondary"}
              title={"<i className='fas fa-download text-gray-700'></i>"}
              setSubmit={() => getReports(start, end, selectedCompany!.value)} />
          )
        }
        }
      />
    </>
  )
}

export { LoginInformationsListModal }
