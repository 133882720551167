import API from './Api'
import { IExamDictionaryModel } from '../../models/responses/ExamDictionaryModel'

class ExamTypeApi {

  async GetExamTypeDictionary() {
    return await API.get<IExamDictionaryModel[]>(`ex/CompanyManager/ExamTypes/dictionary`)
  }
}

export default new ExamTypeApi()
