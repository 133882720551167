export const isImage = (ext: string) => {
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'png':
            return true;
    }
    return false;
}

export const isVideo = (ext: string) => {
    switch (ext.toLowerCase()) {
        case 'avi':
        case 'mpg':
        case 'mp4':
            return true;
    }
    return false;
}

export const isSound = (ext: string) => {
    switch (ext.toLowerCase()) {
        case 'mp3':
        case 'wav':
            return true;
    }
    return false;
}

export const downloadFile = (data: any, fileName: string, type: string = 'text/csv') => {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName);
    a.click()
}

export const downloadPdfFile = (data: any, fileName: string) => {
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName);
    a.click()
}
