import { FC } from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import { createTheme } from '@material-ui/core/styles'

type Props = {
    data: any
    columns: MUIDataTableColumnDef[]
    title: string
    customOptions?: MUIDataTableOptions
    currentPageNumber: number
    currentTotalRecords: number
    size?: number,
    setPageSize: (size: number) => void
    setPageNumber: (number: number) => void
    setSearchText: (text: string) => void
    setSort: (text: string) => void
    exportExcel?: () => void
    Content?: React.ElementType
}


const GenericTable: FC<Props> = ({ data, columns, title, customOptions, currentPageNumber, currentTotalRecords, size = 10, setPageSize, setPageNumber, setSearchText, setSort, exportExcel, Content }) => {

    const HeaderElements = () => {
        return (
            <>
                {
                    Content != undefined &&
                    <Content />
                }
                {
                    exportExcel != undefined &&
                    <button onClick={() => exportExcel()} className='btn btn-icon btn-active-light-dark btn-circle btn-custom'> <i className='fas fa-download text-gray-700'></i></button>
                }
            </>
        )
    };


    let timeout: any = null
    const defaultOptions: MUIDataTableOptions = {
        customToolbar: () => (<HeaderElements />),
        elevation: 0,
        serverSide: true,
        selectableRows: 'none',
        count: currentTotalRecords,
        page: (currentPageNumber - 1),
        pagination: true,
        download: false,
        print: false,
        filter: false,
        rowsPerPageOptions: [5, 10, 20, 100],
        rowsPerPage: size,
        onChangePage: (currentPage) => {
            handleChangePage(currentPage + 1)
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageNumber(1)
            handleChangeSize(numberOfRows)
        },
        onSearchChange: (searchText) => {
            setPageNumber(1)
            clearTimeout(timeout);
            if (searchText && searchText.length > 2) {
                timeout = setTimeout(() => {
                    setSearchText(searchText);
                }, 500)
            } else {
                setSearchText('');
            }
        },
        onColumnSortChange: (changedColumn, direction) => {
            setPageNumber(1)
            setSort(`${changedColumn}_${direction}`);
        },
        onSearchClose: () => {
            options.searchText = ''
            setSearchText('');
        },
        onSearchOpen: () => {
            options.searchText = ''
        }
    };

    const options = { ...defaultOptions, ...customOptions };

    function handleChangeSize(pageSize: number) {
        setPageSize(pageSize)
    }

    function handleChangePage(pageNumber: number) {
        setPageNumber(pageNumber)
    }
    const getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    zIndex: 0
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    fontSize: 14,
                }
            },
            MuiTableBody: {
                root: {
                    resize: 'block'
                }
            }
        }
    })
    return (
        <div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={data}
                    title={title}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </div>
    )
}

export { GenericTable }