import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { FC, useCallback, useEffect, useState } from 'react'
import { IExamAnswerFormModel } from '../../../models/ExamAnswerFormModel'
import { FileTypeEnum } from "../../../../enums/FileTypeEnum"

type Props = {
    answers?: IExamAnswerFormModel[]
    setAnswers: (details: IExamAnswerFormModel[], index?: number) => void
    questionIndex?: number
}
export const Answers: FC<Props> = ({ answers, setAnswers, questionIndex }) => {
    const [isMount, setMount] = useState<number>(0)

    const init = useCallback(async () => {
        if ((answers) || isMount > 1000) {
            var newAnswers = answers!!
            newAnswers!!.map((answer, index) => {
                newAnswers[index].picture = `${process.env.REACT_APP_CDN_URL}files/${FileTypeEnum.Exam.toLowerCase()}/${answer.image}`
            });
            setAnswers([...newAnswers], questionIndex)

        } else {
            setMount(isMount + 1)
        }

    }, [answers, isMount]);

    useEffect(() => {
        init()
    }, [isMount])

    const addNewAnswer = () => {
        var newAnswers = answers || []
        if (newAnswers.length < 26) {
            newAnswers.push({ text: '', isCorrect: false })
            setAnswers([...newAnswers], questionIndex)
        }
    }

    const handleChangeText = (e: any, index: number) => {
        var newAnswers = answers!!
        if (newAnswers[index]) {
            newAnswers[index].text = e.target.value
        }
        setAnswers(newAnswers, questionIndex)
    }

    const handleChangeIsCorrect = (index: number) => {
        var newAnswers = answers!!
        if (newAnswers[index]) {
            newAnswers[index].isCorrect = !newAnswers[index].isCorrect
        }
        setAnswers(newAnswers, questionIndex)
    }

    const handleChangeImage = (event: any, index: number) => {
        if (event.target.files[0] != null) {
            var newFiles = answers!!
            if (newFiles[index]) {
                newFiles[index].file = event.target.files[0]
                newFiles[index].picture = URL.createObjectURL(event.target.files[0])
            }
            setAnswers([...newFiles], questionIndex)
        }
    }
    return (
        <>
            <div className='row'>
                {answers?.map((item, index) => {
                    return (
                        <div className='col-lg-3 mb-5' key={index}>
                            <div className='card border border-dark border-dashed'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-12 mb-6'>
                                            <label className='form-label fw-bolder required'>Text</label>
                                            <textarea
                                                rows={2}
                                                defaultValue={answers[index].text}
                                                onChange={(event) => handleChangeText(event, index)}
                                                className='form-control form-control-solid mb-6'
                                            >
                                            </textarea>
                                            <label className='form-label fw-bolder'>Image</label>
                                            <div
                                                className='image-input image-input-outline'
                                                data-kt-image-input='true'
                                                style={{
                                                    backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`,
                                                    minWidth: '100%',
                                                }}
                                            >
                                                <div
                                                    className='image-input-wrapper'
                                                    style={{
                                                        backgroundImage: `url(${answers[index].picture})`,
                                                        minWidth: '100%',
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center',
                                                    }}
                                                ></div>
                                                <label
                                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow'
                                                    data-kt-image-input-action='change'
                                                    data-bs-toggle='tooltip'
                                                    title=''
                                                    data-bs-original-title='Change avatar'
                                                >
                                                    <i className='fas fa-pencil-alt'></i>
                                                    <input
                                                        type='file'
                                                        value={undefined}
                                                        onChange={(event) => handleChangeImage(event, index)}
                                                        accept='.png, .jpg, .jpeg'
                                                    />
                                                </label>
                                                <span
                                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                                    data-kt-image-input-action='cancel'
                                                    data-bs-toggle='tooltip'
                                                    title=''
                                                    data-bs-original-title='Cancel avatar'
                                                >
                                                    <i className='bi bi-x fs-2'></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span className="badge badge-circle bg-light-success text-dark">{String.fromCharCode(97 + index).toUpperCase()}.)</span>
                                            </div>
                                            <div className='col' style={{ "textAlign": "right" }}>
                                                <div className='form-check form-switch form-check-custom form-check-solid'>
                                                    <input
                                                        className='form-check-input h-30px w-50px'
                                                        type='checkbox'
                                                        name='isCorrect'
                                                        defaultChecked={answers[index].isCorrect}
                                                        onClick={() => handleChangeIsCorrect(index)}
                                                    />
                                                    <label className='form-check-label'>Correct </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="row">
                <div className="col">
                    <span className="badge badge-square badge-dark badge-lg">Q:{questionIndex!! + 1}</span>
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                    <button type='button' onClick={() => addNewAnswer()} className='btn btn-success btn-sm'>
                        <i className='fa fa-plus'></i> Add Answer
                    </button>
                </div>
            </div>
        </>
    )
}