import { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import { IAddStudentPlanRequestModel, addStudentPlanInitValues } from '../../../../models/requests/AddStudentPlanRequestModel'
import { StudentPlanFormValidations } from '../../../validations/StudentPlanFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import moment from 'moment'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import { useParams } from 'react-router-dom'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
  trainers: SelectModel[]
}

const StudentCallPlanFormModal: FC<Props> = ({ itemId, show, handleClose, handleSuccess, trainers }) => {
  const { id } = useParams<any>()

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };
  const typeList = EnumToList(ScheduleTypeEnum);
  const dayList = EnumToList(DayOfWeekEnum);

  const [selectedType, setSelectedType] = useState<SelectModel>(defaultSelect)
  const [selectedDay, setSelectedDay] = useState<SelectModel>(defaultSelect)
  const [selectedTrainer, setSelectedTrainer] = useState<SelectModel>(defaultSelect)

  const formik = useFormik<IAddStudentPlanRequestModel>({
    initialValues: addStudentPlanInitValues,
    enableReinitialize: true,
    validationSchema: StudentPlanFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IAddStudentPlanRequestModel = {
          studentId: id!!,
          type: parseInt(selectedType.value),
          dayOfWeek: parseInt(selectedDay.value),
          trainerId: selectedTrainer.value == '0' ? undefined : selectedTrainer.value,
          startDate: values.startDate,
          duration: values.duration
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IAddStudentPlanRequestModel) {
    try {
      var result = undefined
      result = await StudentApi.AddStudentPlan(model)
      
      formik.resetForm()
      handleClose()
      handleSuccess()
    } catch (error: any) {

      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    formik.resetForm()
  }, [itemId, show])

  const handleChangeType = (e: any) => {
    setSelectedType(e);
  }

  const handleChangeDay = (e: any) => {
    setSelectedDay(e);
  }

  const handleChangeTrainer = (e: any) => {
    setSelectedTrainer(e);
  }
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Call Plan</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Schedule Type</label>
                <Select
                  {...formik.getFieldProps('scheduleType')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeType(event)}
                  value={selectedType}
                  options={typeList}
                />
                <FormikValidationError touched={formik.touched.type} error={formik.errors.type} />
              </div>
              <div className='col-lg-8'>
                <label className='form-label fw-bolder'>Trainer</label>
                <Select
                  {...formik.getFieldProps('trainer')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeTrainer(event)}
                  value={selectedTrainer}
                  options={[defaultSelect, ...trainers]}
                />
                <FormikValidationError touched={formik.touched.trainerId} error={formik.errors.trainerId} />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Day of Week</label>
                <Select
                  {...formik.getFieldProps('dayOfWeek')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeDay(event)}
                  value={selectedDay}
                  options={dayList}
                />
                <FormikValidationError touched={formik.touched.dayOfWeek} error={formik.errors.dayOfWeek} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Start Time</label>
                <input
                  {...formik.getFieldProps('startDate')}
                  type='time'
                  name='startDate'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.startDate} error={formik.errors.startDate} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Minutes</label>
                <input
                  {...formik.getFieldProps('duration')}
                  type='number'
                  name='duration'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.duration} error={formik.errors.duration} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { StudentCallPlanFormModal }
