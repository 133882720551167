import * as Yup from 'yup'


const UserFormValidations = () => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    return Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Minimum 2 symbols')
            .max(45, 'Maximum 45 symbols'),
        lastName: Yup.string()
            .min(2, 'Minimum 2 symbols')
            .max(45, 'Maximum 45 symbols'),
        email: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid'),
        password: Yup.string()
            .required('No password provided.'),
        passwordValid: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
            .when("password", {
                is: (val: string | any[]) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                )
            })
    })
}
const UserImportFormValidations = () => {
    return Yup.object().shape({

    })
}

export { UserFormValidations, UserImportFormValidations }
