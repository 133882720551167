import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { ISchedulesModel } from '../../../../models/responses/ScheduleModel'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { Link } from 'react-router-dom'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const ScheduleListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('title_asc')

    const d = new Date();
    let weekDay = d.getDay()
    weekDay = DayOfWeekEnum.Monday

    var now = `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`
    const [schedules, setSchedules] = useState<PagedResponseModel<ISchedulesModel[]>>()
    const [users, setUsers] = useState<IUserDictionaryModel[]>([])
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    async function init() {

        if (user.companyId) {
            var companyIds = await CompanyApi.GetSubCompaniesDictionary(user.companyId);

            const paginationFilter: IPaginationFilter = {
                page: pageNumber,
                size: pageSize,
                orderBy: sort,
                search: searchText,
            }

            var response = await ScheduleApi.GetSchedules(paginationFilter, {
                companyIds: companyIds.data.map(i => i.id),
                dayOfWeek: weekDay,
                startDate: '00:00:00',
                endDate: '23:59:59'
            })
            setSchedules(response.data)

            var studentIds = response.data.items.map(s => s.studentIds.map(i => i).toString());
            var responseUsers = await UserApi.GetUserDictionaryIds([...studentIds, ...response.data.items.map(i => i.trainerId!!)])
            var dataUsers: IUserDictionaryModel[] = responseUsers.data
            setUsers(dataUsers)
        }
    }

    useEffect(() => {
        init()
    }, [refresh])

    return (
        <>

            <GenericTable
                title='Schedule'
                data={schedules?.items}
                currentTotalRecords={schedules?.totalRecords!!}
                currentPageNumber={schedules?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{dataIndex + 1}</div>
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "Student", name: "studentIds", options: {
                            customBodyRender: (value) => {
                                return <b>{
                                    value.map((item: any) => {
                                        return (
                                            <b><Link to={'/account/users/' + value + '/overview'}>{users?.find(i => i.id == item)?.firstName} {users?.find(i => i.id == item)?.lastName}</Link></b>
                                        )
                                    })
                                }</b>
                            }
                        }
                    },
                    {
                        label: "Planned", name: "plannedDate", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Status", name: "status", options: {
                            customBodyRender: (value) => {
                                let color = "secondary"
                                switch (value) {
                                    case ScheduleStatusEnum.Completed:
                                        color = "success"
                                        break;
                                      case ScheduleStatusEnum.Canceled:
                                        color = "info"
                                        break;
                                      case ScheduleStatusEnum.MeetingCreateError:
                                      case ScheduleStatusEnum.TechnicalDifficulty:
                                        color = "danger"
                                        break;
                                      case ScheduleStatusEnum.NotAnswered:
                                        color = "warning"
                                        break;
                                      case ScheduleStatusEnum.Closed:
                                        color = "dark"
                                        break;
                                      case ScheduleStatusEnum.Ready:
                                      case ScheduleStatusEnum.Started:
                                        color = "primary"
                                        break;
                                    default:
                                        break;
                                }

                                return <span className={`badge bg-${color}`}>
                                    {ScheduleStatusEnum[value]}</span>
                            }
                        }
                    },
                    {
                        label: "Trainer", name: "trainerId", options: {
                            customBodyRender: (value) => {
                                return <b><Link to={'/account/users/' + value + '/overview'}>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</Link></b>
                            }
                        }
                    },
                    {
                        label: "Type", name: "type", options: {
                            customBodyRender: (value) => {
                                return <>{ScheduleTypeEnum[value]}</>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={schedules?.items[dataIndex]?.id!!}
                                        itemParam={schedules?.items[dataIndex]?.id!!}
                                        types={[TableHelperButtonEnum.View]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { ScheduleListModal }
