import { downloadFile } from "../../app/helpers/FileHelper";

class ReportApi {

    async GetJobInterviewReport(model: any) {
        const API_URL = process.env.REACT_APP_STUDENT_URL

        const requestOptions = {
            method: 'POST',

            body: JSON.stringify(model)
        };
        fetch(`${API_URL}pdfengine/output/jobinterview.php`, requestOptions)
            .then(res => res.blob())
            .then(blob => downloadFile(blob, model.title + '.pdf', 'application/pdf'))
            .catch((err) => {
                console.log(err.message);
            });
    }
}

export default new ReportApi()