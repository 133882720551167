/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { RoleEnum } from '../../../../enums/RoleEnum'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import { KTSVG } from '../../../../_metronic/helpers'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import DualListBox from 'react-dual-listbox';
import { SelectModel } from '../../../models/SelectModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { DeleteModal } from '../../../components/modals/DeleteModal'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { EnumToList } from '../../../helpers/EnumHelper'
import { IChangeLevelsRequestModel } from '../../../../models/requests/ChangeLevelsRequestModel'
import { ISchedulePlanModel, ITrainerPlanModel } from '../../../../models/responses/TrainerModel'
import { TrainerCallPlanFormModal } from '../../../pages/trainers/_modals/TrainerCallPlanFormModal'
export function TrainerCallPlans() {

  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)


  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const { id } = useParams<any>()

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
  const dayOfWeekList = EnumToList(DayOfWeekEnum);

  const [plans, setPlans] = useState<ITrainerPlanModel[]>([])
  const [availablePlans, setAvailablePlans] = useState<ITrainerPlanModel[]>([])
  const [schedulePlans, setSchedulePlans] = useState<ISchedulePlanModel[]>([])

  const [selectedLevels, setSelectedLevels] = useState<string[]>([])

  const init = (async () => {
    var responseLevels = await LevelApi.GetLevelDictionary()
    var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setLevelDictionary(dataLevels)

    var responseTrainer = await TrainerApi.GetTrainer(id!!)
    if (responseTrainer.data.trainerPlans != null)
      setPlans(responseTrainer.data.trainerPlans)
    if (responseTrainer.data.availablePlans != null)
      setAvailablePlans(responseTrainer.data.availablePlans)
    if (responseTrainer.data.schedulePlans != null)
      setSchedulePlans(responseTrainer.data.schedulePlans)
    if (responseTrainer.data.responsibleLevels != null)
      setSelectedLevels([...responseTrainer.data.responsibleLevels.map(s => s.toString())])
  });

  async function deleteCallPlan(planId: string) {
    var response = await TrainerApi.DeleteCallPlan({ trainerId: id!!, trainerPlanId: planId })
    init();
  }

  async function saveResponsibleLevelsList() {
    try {
      var newList: number[] = [];
      selectedLevels.forEach(element => {
        newList.push(parseInt(element.toString()));
      });

      var model: IChangeLevelsRequestModel = {
        trainerId: id!!,
        responsibleLevels: newList
      }
      await TrainerApi.ChangeLevels(model)
      setComplete(true)
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  useEffect(() => {
    if (!user.roles?.some(s => s == RoleEnum.Student || s == RoleEnum.Trainer)) {
      // document.location.href = "/error/404"
    } else {
      init()
    }
  }, [user])

  const handleOnChangeLevelList = (selected: string[]) => {
    setSelectedLevels(selected.filter(i => levelDictionary.some(x => x.value.toString() == i)))
  }
  return (
    <>

      <div className='row mb-7'>
        <div className='col-lg-12'>
          <TrainerCallPlanFormModal plans={plans} itemId={itemId} show={showForm} handleClose={handleCloseForm} handleSuccess={init} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Student Call Plan'
            confirm={deleteCallPlan}
          />
          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-2 card-rounded h-200px w-100 bg-danger`}>
                <div className="d-flex flex-stack">
                  <h3 className="m-0 mt-10 text-white fw-bolder fs-3">Trainer Work Plans</h3>
                  <div className="ms-1">
                    <button type="button"
                      onClick={() => onClickItem('', '', 'create')}
                      className="btn btn-dark btn-sm btn-color-white btn-active-white btn-active-color-primary border-0 me-n3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <i className='fa fa-plus'></i> Add Plan
                    </button>
                  </div>
                </div>
              </div>
              <div className='row' style={{ marginTop: '-100px' }}>
                {dayOfWeekList.map((day, dayIndex) => {
                  return (
                    plans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).length > 0 &&
                    <div className='col-4' key={dayIndex}>
                      <div
                        className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                      >
                        <h3 className='mb-5'>{day.label}</h3>
                        {plans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).map((item, index) => {
                          return (
                            <>
                              <div className={'d-flex align-items-center ' + (index != plans.length - 1 ? 'mb-6' : '')} key={index}>
                                <div className='symbol symbol-45px w-40px me-5' onClick={() => onClickItem(item.id!!, `${DayOfWeekEnum[item.dayOfWeek]}, ${item.startDate} - ${item.endDate}`, 'delete')}>
                                  <button className='btn btn-sm btn-icon btn-color-danger btn-active-white symbol-label'>
                                    <KTSVG path='/media/icons/duotune/General/gen027.svg' className='svg-icon-1' />
                                  </button>
                                </div>
                                <div className='symbol symbol-45px w-40px me-5' onClick={() => onClickItem(item.id!!, `${DayOfWeekEnum[item.dayOfWeek]}, ${item.startDate} - ${item.endDate}`, 'edit')}>
                                  <button className='btn btn-sm btn-icon btn-color-primary btn-active-white symbol-label'>
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                  </button>
                                </div>
                                <div className='d-flex align-items-center flex-wrap w-100'>
                                  <div className='mb-1 pe-3 flex-grow-1'>
                                    <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                      {item.startDate} - {item.endDate}
                                    </span>
                                    <div className='text-gray-400 fw-bold fs-7'></div>
                                  </div>
                                </div>

                              </div>

                              {schedulePlans.filter(s => s.dayOfWeek == parseInt(day.value.toString()) && s.startDate >= item.startDate && s.endDate <= item.endDate).map((item, index) => {
                                return (
                                  <span key={index} className='badge badge-light-primary mx-1 mb-1 mt-1'>{item.startDate} - {item.endDate}</span>
                                )
                              })}
                              <hr />
                            </>
                          )
                        }
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className={`card card-xl-stretch mb-xl-8`}>
            <div className='card-body p-0'>
              <div className={`px-9 pt-2 card-rounded h-200px w-100 bg-success`}>
                <div className="d-flex flex-stack">
                  <h3 className="m-0 mt-10 text-white fw-bolder fs-3">Available Plans</h3>
                </div>
              </div>
              <div className='row' style={{ marginTop: '-100px' }}>

                {dayOfWeekList.map((day, dayIndex) => {
                  return (
                    availablePlans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).length > 0 &&
                    <div className='col-4' key={dayIndex}>
                      <div
                        className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'>
                        <h3 className='mb-5'>{day.label}</h3>
                        {availablePlans.filter(i => i.dayOfWeek == parseInt(day.value.toString())).map((item, index) => {
                          return (
                            <li className="d-flex align-items-center py-2" key={index}>
                              <span className="bullet me-5"></span>
                              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                {item.startDate} - {item.endDate} <span className='badge badge-light'>{item.duration} Minutes</span>
                              </span>

                            </li>
                          )
                        }
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='position-relative z-index-1'>
            <div className='form-control px-6 py-9 '>
              <div className='d-flex flex-stack'>
                <h3>Levels</h3>
                <h3 className='text-end text-success'>Responsible Levels</h3>
              </div>
              <DualListBox
                canFilter
                options={levelDictionary}
                selected={selectedLevels}
                onChange={handleOnChangeLevelList}
              />
              <div className='text-end mt-5'>
                <SaveChangesButton
                  loading={loading}
                  setLoading={setLoading}
                  complete={complete}
                  setComplete={setComplete}
                  valid={true}
                  submit={true}
                  setSubmit={saveResponsibleLevelsList} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
