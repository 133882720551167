export interface IScheduleStatisticsModel {
    autoCommentNumber: number,
    canceledCallNumber: number,
    closedCallNumber: number,
    completedCallNumber: number,
    demoStudentNumber: number,
    frozenStudent: number,
    frozenTrainer: number,
    jobInterviewStudentNumber: number,
    notAnsweredCallNumber: number,
    startedCallNumber: number,
    waitingCallNumber: number,
    studentNumber: number,
    trainerNumber: number
}

const scheduleStatisticsInitValues: IScheduleStatisticsModel = {
    autoCommentNumber: 0,
    canceledCallNumber: 0,
    closedCallNumber: 0,
    completedCallNumber: 0,
    demoStudentNumber: 0,
    frozenStudent: 0,
    frozenTrainer: 0,
    jobInterviewStudentNumber: 0,
    notAnsweredCallNumber: 0,
    startedCallNumber: 0,
    waitingCallNumber: 0,
    studentNumber: 0,
    trainerNumber: 0
}

export { scheduleStatisticsInitValues }
