/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/sections'
        icon='/media/icons/duotune/general/gen008.svg'
        title='Sections'
        fontIcon='bi-layers'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>SCHEDULE</span>
        </div>
      </div>
      <AsideMenuItem
        to='/schedule/list'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Schedule'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/schedule/studentPlans'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Student Plans'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/schedule/trainerWorkPlans'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Trainer Work Plans'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>EDUCALL</span>
        </div>
      </div>
      <AsideMenuItem
        to='/mission/missions'
        icon='/media/icons/duotune/files/fil002.svg'
        title='Missions'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/topic/topics'
        icon='/media/icons/duotune/files/fil003.svg'
        title='Topics'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/exam/exams'
        icon='/media/icons/duotune/abstract/abs015.svg'
        fontIcon='bi-layers'
        title='Exams' />
      {/* <AsideMenuItem
        to='/exam/results'
        icon='/media/icons/duotune/abstract/abs046.svg'
        fontIcon='bi-layers'
        title='Results' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>STUDENT REPORTS</span>
        </div>
      </div>
      <AsideMenuItem
        to='/report/studentReports'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
        title='Student Reports' />
      <AsideMenuItem
        to='/report/loginInformations'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
        title='Login Informations' />
      <AsideMenuItem
        to='/report/jobInterviews'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
        title='Job Interviews' />
      <AsideMenuItem
        to='/report/nativeRatings'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
        title='Native Ratings' />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ACCOUNTS</span>
        </div>
      </div>

      <AsideMenuItem
        to='/users/student'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Students'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/users/trainer'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Trainers'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
