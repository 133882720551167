import { DayOfWeekEnum } from '../../enums/DayOfWeekEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'

export interface ITrainerModel {
    id?: string
    trainerPlans: ITrainerPlanModel[]
    availablePlans: ITrainerPlanModel[]
    schedulePlans: ISchedulePlanModel[]
    responsibleLevels: number[]
}

const trainerInitValues: ITrainerModel = {
    id: undefined,
    availablePlans: [],
    trainerPlans: [],
    schedulePlans: [],
    responsibleLevels: []
}

export interface ITrainerSettingsModel {
    id?: string
    isAvailableToCall: boolean    
    companyId: string
}

const trainerSettingsInitValues: ITrainerSettingsModel = {
    id: undefined,
    isAvailableToCall: false,    
    companyId: ''
}


export interface ITrainersModel {
    id?: string
    trainerPlans: ITrainerPlanModel[]
    availablePlans: ITrainerPlanModel[]
    schedulePlans: ISchedulePlanModel[]
    responsibleLevelCount: number
    studentConsultancyCount: number
    todayMinutes: number
    weekMinutes: number
}
const trainersInitValues: ITrainersModel = {
    id: undefined,
    availablePlans: [],
    trainerPlans: [],
    schedulePlans: [],
    responsibleLevelCount: 0,
    studentConsultancyCount: 0,
    todayMinutes: 0,
    weekMinutes: 0
}

export interface ITrainerPlanModel {
    id?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number
}

const trainerPlanInitValues: ITrainerPlanModel = {
    id: undefined,
    dayOfWeek: DayOfWeekEnum.Monday,
    startDate: '',
    endDate: '',
    duration: 0
}

export interface ISchedulePlanModel {
    id?: string,
    title: string,
    studentId: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    capacity: number,
    empty: number,
    studentPlanId: string,
    scheduleType: ScheduleTypeEnum,
    topicId?: string,
    topics?: IScheduleTopicStatus[],
    levelId?: number,
    trainerId?: string
}

export interface IScheduleTopicStatus {
    topicId: string,
    isComplete: boolean
}

const schedulePlanInitValues: ISchedulePlanModel = {
    id: undefined,
    title: '',
    studentId: '',
    dayOfWeek: DayOfWeekEnum.Friday,
    startDate: '',
    endDate: '',
    duration: 0,
    capacity: 0,
    empty: 0,
    studentPlanId: '',
    scheduleType: ScheduleTypeEnum.Group,
    topicId: undefined,
    topics: [],
    levelId: undefined,
    trainerId: undefined
}


export { trainerInitValues, trainersInitValues, trainerPlanInitValues, trainerSettingsInitValues, schedulePlanInitValues }
