import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { SplashScreen } from '../../../components/partials/SplashScreen'
import { signinRedirectCallback, getUserContext } from '../redux/AuthService'

export function Callback() {
    const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

    const dispatch = useDispatch()
    useEffect(() => {
        if (!isAuthorized) {
            signinRedirectCallback().then(async user => {
                dispatch(auth.actions.login(user.access_token))
                const userData = await getUserContext();
                dispatch(auth.actions.fulfillUser(userData))
                document.location.href = "/dashboard"

            })
        } else {
            document.location.href = "/dashboard"
        }
    })
    return (
        <SplashScreen></SplashScreen>
    )
}
