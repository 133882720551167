export enum ScheduleScoreTypeEnum {
    CallQuality = 1,
    Confidence = 2,
    Attendance = 3,
    VocabularyUsage = 4,
    GrammarUsage = 5,
    Pronunciation = 6,
    Fluency = 7,
    LongAndComplexSentences = 8,
    Accuracy = 9,
    Advices = 10
}