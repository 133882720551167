/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import MissionApi from '../../../../infrastructure/api/MissionApi'
import { IMissionModel } from '../../../../models/responses/MissionModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { DeleteModal } from '../../../components/modals/DeleteModal'
import { MissionFormModal } from '../../../pages/missions/_modals/MissionFormModal'
import { KTSVG } from '../../../../_metronic/helpers'
import { StatisticsWidget6 } from '../../../../_metronic/partials/widgets/statistics/StatisticsWidget6'
import { UserModel } from '../../auth/models/UserModel'

export function Missions() {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShowDelete = () => setShowDelete(true)

    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const userAuth: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
    const levelId: number = parseInt(user.details?.find(i => i.type == "LevelId")?.value!!);
    const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
    const [selectedLevel, setSelectedLevel] = useState<SelectModel>(selectInitValues)
    const [missions, setMissions] = useState<IMissionModel[]>([])
    const [levelDegree, setLevelDegree] = useState<string>("")

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'delete':
                handleShowDelete()
                break
            default:
                handleShowForm()
                break
        }
    }

    const init = useCallback(async () => {
        if (levelDictionary.length < 1) {
            var responseLevels = await LevelApi.GetLevelDictionary()
            var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
                value: d.id.toString(),
                label: d.name + (levelId == d.id ? ' (Present Level)' : '')
            }))]
            setLevelDictionary(dataLevels)
        }

        if (levelId > 0) {
            handleChangeLevel(dataLevels! ?? levelDictionary.find(i => i.value == levelId.toString()))
        } else if (selectedLevel.value !== "") {
            handleChangeLevel(dataLevels! ?? levelDictionary.find(i => i.value == selectedLevel.value.toString()))
        }
    }, [refresh, levelId]);

    useEffect(() => {
        // if (!user.roles?.some(s => s == RoleEnum.Student)) {
        //    document.location.href = "/error/404"
        // } else {
        init()
        // }
    }, [refresh, user])

    const getTopicLists = async (levelId: number) => {
        if (levelId > 0 && userAuth.companyId) {
            var responseTopicList = await MissionApi.GetStudentMissions(user.id, levelId, userAuth.companyId);
            if (responseTopicList.data.missions) {
                setMissions(responseTopicList.data.missions)
                setLevelDegree(responseTopicList.data.levelDegree)
            }
            else {
                setMissions([])
                setLevelDegree("")
            }
        } else {
            setMissions([])
            setLevelDegree("")
        }
    }

    async function deleteMission(itemId: string) {
        if (userAuth.companyId) {
            var response = await MissionApi.DeleteStudentMission(itemId, user.id, userAuth.companyId)
            setRefresh(refresh + 1)
        }
    }

    const handleChangeLevel = (e: any) => {
        setSelectedLevel(e)
        getTopicLists(parseInt(e?.value))
    }

    return (
        <>
            <DeleteModal
                itemId={itemId}
                itemName={itemName}
                handleClose={handleCloseDelete}
                show={showDelete}
                type='Mission'
                confirm={deleteMission}
            />
            {userAuth.companyId &&
                <MissionFormModal itemId={itemId} show={showForm} userId={user.id} companyId={userAuth.companyId} handleClose={handleCloseForm} />
            }
            <div className='row'>
                {levelDegree !== '' &&
                    <div className='col-12'>
                        <StatisticsWidget6
                            className='card-xl-stretch mb-xl-8'
                            color='success'
                            title=''
                            description='Level Degree'
                            progress={`${levelDegree}%`}
                        />
                    </div>
                }

                <div className='col-md-7'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Missions</h3>
                            </div>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a Mission'
                            >
                                <a
                                    href='#'
                                    onClick={() => onClickItem('', '', 'create')}
                                    className='btn btn-sm btn-light-primary'
                                >
                                    <i className="fas fa-plus"></i>
                                    Add Mission
                                </a>
                            </div>
                            <div className='mt-4 col-5'>
                                <Select
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLevel(event)}
                                    value={selectedLevel}
                                    options={levelDictionary}
                                />
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                <div className='row'>
                                    {missions.filter(i => !i.isCompleted && i.requiredNumber > 0).map((item, index) => {
                                        return (
                                            <div className='col-12' key={index}>
                                                <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-3">
                                                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                                        <span className="badge badge-circle badge-dark">{index + 1}</span>
                                                    </span>
                                                    <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                                                        <h5 className="mb-1">{item.title}</h5>
                                                        <span>Required: <b>{item.requiredNumber}</b></span><br />
                                                        <span>Completed: <b>{item.completedNumber}</b></span><br />
                                                        <span>Condition Score: <b>{item.conditionScore}</b></span>
                                                    </div>
                                                    <div className="col d-flex justify-content-end flex-stack text-end">
                                                        <TableHelperButtons
                                                            itemId={item.id!!}
                                                            itemParam={item.title!!}
                                                            types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                                                            onClickItem={onClickItem}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Completed Missions</h3>
                            </div>
                        </div>
                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {missions.filter(i => i.isCompleted).map((item, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-success pe-0 pe-sm-10">
                                                <h5 className="mb-1">{item.title}</h5>
                                                <span>Completed: <b>{item.completedNumber}</b></span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Cancelled Missions</h3>
                            </div>
                        </div>
                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {missions.filter(i => i.requiredNumber == 0).map((item, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-success pe-0 pe-sm-10">
                                                <h5 className="mb-1">{item.title}</h5>
                                            </div>
                                            <div className="col d-flex justify-content-end flex-stack text-end">
                                                <TableHelperButtons
                                                    itemId={item.id!!}
                                                    itemParam={item.title!!}
                                                    types={[TableHelperButtonEnum.Delete]}
                                                    onClickItem={onClickItem}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
