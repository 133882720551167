import { MissionTypeEnum } from "../../enums/MissionTypeEnum"

export interface IMissionModel {
  id?: string
  title: string
  levelId?: number
  userId?: string
  companyId?: string
  type: MissionTypeEnum,
  param: string,
  requiredNumber: number,
  completedNumber: number,
  conditionScore: number,
  isCompleted: boolean,
  isOverride: boolean
}

const missionInitValues: IMissionModel = {
  id: undefined,
  title: '',
  levelId: undefined,
  userId: undefined,
  companyId: undefined,
  type: 0,
  param: '',
  requiredNumber: 0,
  completedNumber: 0,
  conditionScore: 0,
  isCompleted: false,
  isOverride: false
}

export interface IMissionsModel {
  levelDegree: string,
  missions: IMissionModel[]
}

const missionsInitValues: IMissionsModel = {
  levelDegree: "",
  missions: []
}

export { missionInitValues, missionsInitValues }
