import { FC, lazy, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { ResultFormModal } from './_modals/ResultFormModal'
import { ResultViewModal } from './_modals/ResultViewModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import ResultApi from '../../../infrastructure/api/ResultApi'

const ResultListModal = lazy(() => import('./_modals/ResultListModal'))
const ResultPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      case 'view':
        handleShowView()
        break
      default:
        handleShowForm()
        break
    }
  }
  const resultsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Educall',
      path: '#',
      isSeparator: false,
      isActive: false,
    },
  ]

  async function deleteResult(itemId: string) {
    var response = await ResultApi.DeleteResult(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
        </div>

        <div className='card-body py-3'>
          <PageTitle breadcrumbs={resultsBreadCrumbs}>Results</PageTitle>
          <ResultListModal refresh={refresh} onClickItem={onClickItem} />
          <ResultFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <ResultViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />

          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Result'
            confirm={deleteResult}
          />
        </div>
      </div>
    </>
  )
}

const ResultWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <ResultPage />
    </>
  )
}

export { ResultWrapper }
