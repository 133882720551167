import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import {
  IUserDictionaryModel
} from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IScheduleModel, scheduleInitValues } from '../../../../models/responses/ScheduleModel'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { SelectModel } from '../../../models/SelectModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { ITopicModel, topicInitValues } from '../../../../models/responses/TopicModel'
import { ScheduleStatusColorizer } from '../../../functions/ScheduleStatusColorizer'
import parse from 'html-react-parser'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ScheduleViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [schedule, setSchedule] = useState<IScheduleModel>(scheduleInitValues)
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])
  const [topic, setTopic] = useState<ITopicModel>(topicInitValues)
  const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])

  async function init() {
    let result: IScheduleModel
    if (itemId !== '') {
      var response = await ScheduleApi.GetSchedule(itemId)
      result = response.data

      if (response.data.id != null) {
        var responseUsers = await UserApi.GetUserDictionaryIds([result.trainerId, ...result.studentIds,
        ...result.details != null ? result.details.filter(i => i.trainerId != null && i.trainerId != '').map(i => i.trainerId) : [],
        ...result.trainerComments != null ? result.trainerComments.filter(i => i.trainerId != null && i.trainerId != '').map(i => i.trainerId) : []])
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        setUsers(dataUsers)

        var responseLevels = await LevelApi.GetLevelDictionary()
        var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
          value: d.id.toString(),
          label: d.name
        }))]
        setLevelDictionary(dataLevels)

        if (result.topicId != null && result.topicId != '' && result.topicId != '00000000-0000-0000-0000-000000000000') {
          var responseTopic = await TopicApi.GetTopic(result.topicId)
          setTopic(responseTopic.data)
        } else {
          setTopic(topicInitValues)
        }
      }
    } else {
      result = scheduleInitValues
    }
    setSchedule(result)
  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-6'>
            <h1 className='mb-3'>View Schedule Details</h1>
            <div className='text-muted fw-bold fs-5 mb-6'>
              Schedule Management <small>Educall</small>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='card'>
                <div className='card-body p-0'>
                  <div className={`px-9 pt-4 card-rounded h-175px w-100 bg-primary`}>
                    <div className='d-flex text-center flex-column text-white'>
                      <span className='fw-bold fs-7'>Status</span>
                      <span className='fw-bolder fs-2x'>{ScheduleStatusEnum[schedule.status]}</span>
                    </div>
                  </div>
                  <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                    style={{ marginTop: '-100px' }}
                  >
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen009.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {schedule.studentIds.map((student, index) => {
                              var user = users.find(i => i.id == student?.toString());
                              return (
                                <span key={index}>{user!.firstName} {user!.lastName}<br /></span>
                              )
                            })}

                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Student</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG
                            path='/media/icons/duotune/layouts/lay002.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {users.find(i => i.id == schedule.trainerId?.toString())?.firstName} {users.find(i => i.id == schedule.trainerId?.toString())?.lastName}
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Trainer</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-1' />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {topic.title}
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Topic</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG
                            path='/media/icons/duotune/layouts/lay006.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {levelDictionary.find(i => i.value == schedule.levelId?.toString())?.label}
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Level</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG path='/media/icons/duotune/electronics/elc007.svg' className='svg-icon-1' />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {schedule.duration} minutes
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Duration</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG path='/media/icons/duotune/general/gen002.svg' className='svg-icon-1' />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {ScheduleTypeEnum[schedule.type]} {schedule.type == 3 ? `(${schedule.studentIds.length}/${schedule.capacity})` : ``}
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Type</div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-1' />
                        </span>
                      </div>
                      <div className='d-flex align-items-center flex-wrap w-100'>
                        <div className='mb-1 pe-3 flex-grow-1'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {schedule.plannedDate}
                          </span>
                          <div className='text-gray-400 fw-bold fs-7'>Planned Date</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              {schedule.details != null && schedule.details?.length > 0 &&
                <div className="card">
                  <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">Calling Details</span>
                      <span className="text-gray-400 mt-1 fw-bold fs-6"></span>
                    </h3>
                  </div>
                  <div className="card-body pt-5">
                    {schedule.details?.map((item, index) => {
                      var trainer = users.find(i => i.id == item.trainerId);
                      return (
                        <div key={index}>
                          <div className="d-flex flex-stack">
                            < div className="text-gray-700 fw-bold fs-6 me-2">Trainer: {trainer!.firstName} {trainer!.lastName}</div>
                          </div>
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-senter">
                              <span className="text-gray-900 fw-boldest fs-6">{item.createdAt}</span>
                            </div>
                            <div className="d-flex align-items-senter">
                              <span className="text-gray-900 fs-6"><span className={`badge bg-${ScheduleStatusColorizer(item.status)}`}>{ScheduleStatusEnum[item.status]}</span></span>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-3"></div>
                        </div>
                      )
                    })}

                  </div>
                </div>
              }
              {schedule.records != null && schedule.records?.length > 0 &&
                <div className="card">
                  <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">Records</span>
                      <span className="text-gray-400 mt-1 fw-bold fs-6"></span>
                    </h3>
                  </div>
                  <div className="card-body pt-5">
                    {schedule.records?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="d-flex flex-stack">
                            < div className="text-gray-700 fw-bold fs-6 me-2">Url:</div>
                            <div className="d-flex align-items-senter">
                              <a href={(item.fileType == "MP4" ? process.env.REACT_APP_S3_VIDEO_URL : process.env.REACT_APP_S3_AUDIO_URL) + item.url} target="_blank">{item.url}</a>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-3"></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
            </div>
            {schedule.trainerComments != null && schedule.trainerComments?.length > 0 &&
              <div className="col-sm-12 ">
                <div className="card">
                  <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">Trainer Comments</span>
                      <span className="text-gray-400 mt-1 fw-bold fs-6"></span>
                    </h3>
                  </div>
                  <div className="card-body pt-5">
                    {schedule.trainerComments?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="d-flex flex-stack">
                            <div className="text-gray-700 fw-bold fs-6 me-2">Trainer: {users.find(i => i.id == item.trainerId)?.email}
                            </div>
                            <div className="d-flex align-items-senter">
                              <span className="text-gray-900 fs-6">{parse(item.message)}</span>
                            </div>
                            <div className="d-flex align-items-senter">
                              <span className="text-gray-900 fs-6">{item.directMessage}</span>
                            </div>
                            <div className="d-flex align-items-senter">
                              <span className="text-gray-900 fs-6">{item.privateMessage}</span>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-3"></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            }
            {schedule.studentComments != null && schedule.studentComments?.length > 0 &&
              <div className="col-sm-12 ">
                <div className="card">
                  <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">Student Comments</span>
                      <span className="text-gray-400 mt-1 fw-bold fs-6"></span>
                    </h3>
                  </div>
                  <div className="card-body pt-5">
                    {schedule.studentComments?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="text-gray-700 fw-bold fs-6 me-2">{item.message}<br /></div>
                          <div className="d-flex flex-stack">
                            <span className="text-gray-900 fs-6">Difficulty: {item.difficulty}</span>
                            <span className="text-gray-900 fs-6">Usefult: {item.useful}</span>
                            <span className="text-gray-900 fs-6">Pleasure: {item.pleasure}</span>
                            <span className="text-gray-900 fs-6">Prepared: {item.prepared}</span>
                            <span className="text-gray-900 fs-6">Quality: {item.quality}</span>
                          </div>
                          <div className="separator separator-dashed my-3"></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>
            <button className='btn btn-primary' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export { ScheduleViewModal }
