import { FC, useEffect, useState } from 'react'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { ITrainersModel } from '../../../../models/responses/TrainerModel'
import { BasicTable } from '../../../components/tables/BasicTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const TrainerListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('id_desc')

    const d = new Date();
    let weekDay = d.getDay()
    var now = `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`
    const [trainers, setTrainers] = useState<ITrainersModel[]>([])
    const [users, setUsers] = useState<IUserDictionaryModel[]>([])
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    async function init() {

        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await TrainerApi.GetTrainerPlans(paginationFilter,
            {
                dayOfWeek: weekDay,
                companyId: user.companyId
            })
        setTrainers(response.data.items)

        var responseUsers = await UserApi.GetUserDictionaryIds(response.data.items.map(i => i.id!!))
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        setUsers(dataUsers)
    }

    useEffect(() => {
        init()
    }, [refresh])

    return (
        <>
            <BasicTable
                title='Trainer Works'
                data={trainers}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{dataIndex + 1}</div>
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "Name", name: "id", options: {
                            customBodyRender: (value) => {
                                return <b>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</b>
                            }
                        }
                    },
                    {
                        label: "Today First Start", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{trainers[dataIndex].trainerPlans.find(s => s.dayOfWeek == weekDay)?.startDate}</>
                            }
                        }
                    },
                    {
                        label: "Today Last Finish", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{trainers[dataIndex].trainerPlans.reverse().find(s => s.dayOfWeek == weekDay)?.endDate}</>
                            }
                        }
                    },
                    {
                        label: "Today Hours", name: "todayMinutes", options: {
                            customBodyRender: (value) => {
                                return <>{(value / 60).toFixed(1)} Hours</>
                            }
                        }
                    },
                    {
                        label: "Weekly Hours", name: "weekMinutes", options: {
                            customBodyRender: (value) => {
                                return <>{(value / 60).toFixed(1)} Hours</>
                            }
                        }
                    },
                    {
                        label: "Level Number", name: "responsibleLevelCount", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Consultant", name: "studentConsultancyCount", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Working Now", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <div className='ps-5 fw-bolder text-success'>
                                    {trainers[dataIndex].trainerPlans.some(s => s.dayOfWeek == weekDay && s.startDate <= now && now <= s.endDate) ? '✓' : null}
                                </div>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={trainers[dataIndex]?.id!!}
                                        itemParam={trainers[dataIndex]?.id!!}
                                        links={[
                                            { type: 'edit', value: '/account/users/' + trainers[dataIndex]?.id + '/call-plans' }
                                        ]}
                                        types={[]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    filter: false,
                    viewColumns: false,
                    serverSide: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
            />
        </>
    )
}

export { TrainerListModal }
