import React, { createContext, useContext, useState, useEffect } from 'react'
import { DefaultLayoutConfig } from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../../app/modules/auth/models/UserModel'
import { force_logout } from '../../../app/modules/auth/redux/AuthService'
import { RoleEnum } from '../../../enums/RoleEnum'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => { },
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: React.FC = ({ children }) => {

  const authuser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)

  async function checkAuthorize() {

    if (isAuthorized && !authuser.rolesString!!.some(s => s == RoleEnum.CompanyManager)) {
      await force_logout();
    } else {
      disableSplashScreen()
    }
  }

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(async () => {
      await checkAuthorize()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  }

  useEffect(() => {
    checkAuthorize()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
