import { FC, Fragment, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { SelectModel } from '../../../models/SelectModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import * as level from '../../levels/redux/LevelRedux'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { IStudentReportsModel } from '../../../../models/responses/StudentReportsModel'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select';
import { downloadFile } from '../../../helpers/FileHelper'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { ICompanyModel } from '../../../../models/responses/CompanyModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const StudentReportsListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  const excelJS = require("exceljs");

  const [companiesList, setCompaniesList] = useState<ICompanyModel[]>([])
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>()

  const [reports, setReports] = useState<PagedResponseModel<IStudentReportsModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')

  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getUsers(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }

  async function init() {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    if (companies.length < 1 && user.companyId) {
      var companiesQuery = await CompanyApi.GetSubCompaniesDictionary(user.companyId);
      var dataParentCompanies: SelectModel[] = [
        ...companiesQuery.data.map((d) => ({
          value: d.id.toString(),
          label: d.name,
        })),
      ]
      setCompanies(dataParentCompanies)
      setCompaniesList(companiesQuery.data)
    }

    getUsers(start, end);
  }


  useEffect(() => {
    init();

  }, [refresh, pageNumber, pageSize, searchText, sort, selectedCompany])

  const generateRow = (report: IStudentReportsModel) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={2}></td>
          {report != undefined ?
            <>
              <td>
                <TableContainer component={Paper} className={"mt-5"}>
                  <Table size={'small'} aria-label="a dense table">
                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                      <TableRow>
                        <TableCell>Calling Details</TableCell>
                        <TableCell align='right'>Values</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Fragment>
                        <TableRow>
                          <TableCell>Total Call</TableCell>
                          <TableCell align='right' >{report.totalCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Completed Call</TableCell>
                          <TableCell align='right' >{report.completedCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Free Call</TableCell>
                          <TableCell align='right' >{report.freeCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Answer Call</TableCell>
                          <TableCell align='right' >{report.noAnsweredCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Canceled Call</TableCell>
                          <TableCell align='right' >{report.canceledCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Attendance</TableCell>
                          <TableCell align='right' >%{report.attendance}</TableCell>
                        </TableRow>
                      </Fragment>

                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
              <td style={{ "paddingLeft": "10px" }}>
                <TableContainer component={Paper} className={"mt-5"}>
                  <Table size={'small'} aria-label="a dense table">
                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                      <TableRow>
                        <TableCell>Student Details</TableCell>
                        <TableCell align='right'>Values</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Fragment>
                        <TableRow>
                          <TableCell>Current Level</TableCell>
                          <TableCell align='right' >{levelDictionary.find(i => i.value == report.presentLevelId.toString())?.label}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Current Level Degree</TableCell>
                          <TableCell align='right' >%{report.presentLevelDegree}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Startup Level</TableCell>
                          <TableCell align='right' >{levelDictionary.find(i => i.value == report.startupLevelId.toString())?.label}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Remaining Call</TableCell>
                          <TableCell align='right' >{report.remainingCall}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Pleasure</TableCell>
                          <TableCell align='right' >{report.pleasure}</TableCell>
                        </TableRow>
                      </Fragment>

                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </>
            :
            <td>
              <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-5">
                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                  <span>No was ratings.</span>
                </div>
              </div>
            </td>

          }
        </tr>
      </Fragment >

    )
  }

  const options = {
    rowsPerPage: pageSize,
    search: true,
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      var report = reports?.items[rowMeta.rowIndex];
      return (
        generateRow(report!)
      );
    },
  };

  function recursiveParentCompany(companyFields: string[], companyId: string): any {
    var companyFields = companyFields
    var company = companiesList.find(i => i.id == companyId)
    companyFields.push(company?.name!)

    if (company?.parentCompanyId != undefined)
      companyFields = recursiveParentCompany(companyFields, company?.parentCompanyId!);

    return companyFields;
  }

  async function getReports(start: moment.Moment, end: moment.Moment, companyId: string) {
    try {
      if (user.companyId != null && user.companyId != '' && companyId != "") {
        const paginationFilter: IPaginationFilter = {
          page: 1,
          size: 9999,
          orderBy: sort,
          search: searchText
        }

        if (user.companyId && selectedCompany?.value) {

          var subCompanies = await CompanyApi.GetSubCompaniesDictionary(selectedCompany.value);

          var responseData = await ScheduleApi.GetStudentReports(
            paginationFilter, {
            companyIds: subCompanies.data.map(i => i.id),
            startDate: start.format("YYYY-MM-DDT00:00:00"),
            endDate: end.format("YYYY-MM-DDT23:59:59")
          });
          var maxBreakDown = 1;
          responseData.data.items.forEach(report => {
            report.presentLevelName = levelDictionary.find(i => i.value == report.presentLevelId.toString())?.label;
            report.startupLevelName = levelDictionary.find(i => i.value == report.startupLevelId.toString())?.label;

            report.companies = [];
            report.companies = recursiveParentCompany([], report.companyId)
            if (report.companies.length > maxBreakDown) maxBreakDown = report.companies.length;

            for (var i = 0; i < report.companies.length; i++) {
              switch (i) {
                case 0: report.company0 = report.companies.reverse()[i]; break;
                case 1: report.company1 = report.companies.reverse()[i]; break;
                case 2: report.company2 = report.companies.reverse()[i]; break;
                case 3: report.company3 = report.companies.reverse()[i]; break;
                case 4: report.company4 = report.companies.reverse()[i]; break;
                case 5: report.company5 = report.companies.reverse()[i]; break;
                case 6: report.company6 = report.companies.reverse()[i]; break;
                case 7: report.company7 = report.companies.reverse()[i]; break;
                case 8: report.company8 = report.companies.reverse()[i]; break;
                case 9: report.company9 = report.companies.reverse()[i]; break;
              }
            }
          });
          var fields: any[] = []

          for (var i = 0; i < maxBreakDown; i++) {
            fields.push({ key: 'company' + i, header: (i == 0 ? 'Company' : '-'), width: 30 })
          }
          fields = [...fields, ...[
            { key: 'firstName', header: 'First Name', width: 15 },
            { key: 'lastName', header: 'Last Name', width: 15 },
            { key: 'email', header: 'E-mail', width: 25 },
            { key: 'phoneNumber', header: 'Phone Number', width: 15 },
            { key: 'totalCall', header: 'Total Call' },
            { key: 'completedCall', header: 'Completed Call' },
            { key: 'freeCall', header: 'Free Call' },
            { key: 'noAnsweredCall', header: 'No Answer Call' },
            { key: 'canceledCall', header: 'Canceled Call' },
            { key: 'attendance', header: 'Attendance' },
            { key: 'presentLevelName', header: 'Current Level', width: 15 },
            { key: 'presentLevelDegree', header: 'Current Level Degree' },
            { key: 'startupLevelName', header: 'Startup Level', width: 15 },
            { key: 'remainingCall', header: 'Remaining Call' },
            { key: 'pleasure', header: 'Pleasure' },
          ]];

          const workbook = new excelJS.Workbook();
          const worksheet = workbook.addWorksheet("Educall Student Reports");

          worksheet.columns = fields
          worksheet.getRow(1).eachCell((cell: any) => {
            cell.font = { bold: true };
          });
          worksheet.addRows(responseData.data.items);
          const buffer = await workbook.xlsx.writeBuffer();

          downloadFile(buffer, `Educall Student Reports - (${start.format("YYYY-MM-DD")} _ ${end.format("YYYY-MM-DD")}) _ ${moment().format("YYYY-MM-DD-HH-mm-ss")}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
          setComplete(true)
        }

      }
    } catch (error) {
      alert('Request timed out')
      setLoading(false)
    }
  }


  async function getUsers(start: moment.Moment, end: moment.Moment) {

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText
    }

    if (user.companyId && selectedCompany?.value) {

      var companies = await CompanyApi.GetSubCompaniesDictionary(selectedCompany.value);

      var responseData = await ScheduleApi.GetStudentReports(
        paginationFilter, {
        companyIds: companies.data.map(i => i.id),
        startDate: start.format("YYYY-MM-DDT00:00:00"),
        endDate: end.format("YYYY-MM-DDT23:59:59")
      });

      setReports(responseData.data);
    }
  }

  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder'>Company Section</label>
          <Select
            styles={customSelectStyles(true)}
            menuPortalTarget={document.body}
            onChange={(event) => handleChangeCompany(event)}
            value={selectedCompany}
            options={companies}
          />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder'>Date Range</label>
          <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            style={{
              darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
            }}
            end={end}
            local={local}
            applyCallback={applyCallback}
          >
            <input
              id="formControlsTextB"
              className='form-control form-control-solid'
              value={`${start.format(local.format)} - ${end.format(local.format)}`}
              type="text"
            />
          </DateTimeRangeContainer>
        </div>
      </div>
      <GenericTable
        title={'Student Reports'}
        data={reports?.items}
        currentTotalRecords={reports?.totalRecords!!}
        currentPageNumber={reports?.pageNumber!!}
        columns={[
          {
            label: "#", name: "#", options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            }
          },
          {
            label: "FullName", name: "firstName", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{reports?.items[dataIndex].firstName} {reports?.items[dataIndex].lastName}</b>
                )
              },
            },
          },
          { label: "Email", name: "email" },
          { label: "Phone Number", name: "phoneNumber" },
          {
            label: "#", name: "Id", options: {
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={reports?.items[dataIndex]?.id!!}
                    itemParam={`${reports?.items[dataIndex]?.firstName!!} ${reports?.items[dataIndex]?.lastName!!}`}
                    links={[
                      { type: 'user', value: '/account/users/' + reports?.items[dataIndex]?.id!! },

                    ]}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false
            },
          }
        ]}
        customOptions={options}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
        Content={() => {
          return (
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={true}
              submit={true}
              customClasses={!loading ? "btn-sm bg-transparent btn-outline-primary btn-icon btn-active-light-dark btn-circle btn-custom" : "btn-sm btn-secondary"}
              title={"<i className='fas fa-download text-gray-700'></i>"}
              setSubmit={() => getReports(start, end, selectedCompany!.value)} />
          )
        }
        }
      />
    </>
  )
}

export { StudentReportsListModal }
