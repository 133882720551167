/* eslint-disable jsx-a11y/anchor-is-valid */
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'

export function Overview() {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Overview</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{user?.firstName} {user?.lastName}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>E-mail</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 me-2'>{user?.email}</span>
              {user?.emailConfirmed ? <span className='badge badge-success'>Verified</span>
                : <span className='badge badge-danger'>Not Verified</span>}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Phone Number</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 me-2'>{user?.phoneNumber}</span>
              {user?.phoneNumberConfirmed ? <span className='badge badge-success'>Verified</span>
                : <span className='badge badge-danger'>Not Verified</span>}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>User Name</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 me-2'>{user?.userName!! || ''}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              User Status
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{user.isActive ? "Active" : "Passive"}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Created At</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{user.createdAt}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Last Modified At</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{user.modifiedAt}</span>
            </div>
          </div>

          {false && user.details?.map((item, index) => {
            return (
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>{item.type}</label>

                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>{item.value}</span>
                </div>
              </div>)
          })}
        </div>
      </div>

    </>
  )
}
