import API from './Api'
import { UnitTypeEnum } from '../../enums/UnitTypeEnum'
import { IUnitDictionaryModel } from '../../models/responses/UnitDictionaryModel'

class UnitApi {
  async GetUnitDictionary(type?: UnitTypeEnum) {
    return await API.get<IUnitDictionaryModel[]>(`e/CompanyManager/Units/dictionary` + (type != undefined ? `/${type}` : ''))
  }
}

export default new UnitApi()
