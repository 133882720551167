import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { IUserModel, userInitValues } from '../../../../models/responses/UserModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SettUser: '[Set User] Action',
    SetRoles: '[Set Roles] Action',
    SetCompaniesUsers: '[Set CompaniesUsers] Action',
    SetSelectedUser: '[Set SelectedUser] Action',
    UpdateSelectedUser: '[Update SelectedUser] Action'
}

export interface IAccountState {
    roles?: Array<string>,
    selectedUser?: IUserModel,
    companiesUsers?: Array<IUserDictionaryModel>
    updateSelectedUser?: boolean
}

const initialAccountState: IAccountState = {
    roles: [],
    companiesUsers: [],
    selectedUser: userInitValues,
    updateSelectedUser: false
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-account',
        whitelist: ['companiesUsers'],
        blacklist: ['roles', 'selectedUser', 'updateSelectedUser']
    },
    (state: IAccountState = initialAccountState, action: ActionWithPayload<IAccountState>) => {
        switch (action.type) {
            case actionTypes.SetRoles: {
                const roles = action.payload?.roles
                return { ...state, roles }
            }
            case actionTypes.SetSelectedUser: {
                const selectedUser = action.payload?.selectedUser
                return { ...state, selectedUser }
            }
            case actionTypes.SetCompaniesUsers: {
                const companiesUsers = action.payload?.companiesUsers
                return { ...state, companiesUsers }
            }
            case actionTypes.UpdateSelectedUser: {
                const updateSelectedUser = action.payload?.updateSelectedUser
                return { ...state, updateSelectedUser }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setRoles: (roles: Array<string>) => ({ type: actionTypes.SetRoles, payload: { roles } }),
    setCompaniesUsers: (companiesUsers: Array<IUserDictionaryModel>) => ({ type: actionTypes.SetCompaniesUsers, payload: { companiesUsers } }),
    setSelectedUser: (selectedUser: IUserModel) => ({ type: actionTypes.SetSelectedUser, payload: { selectedUser } }),
    updateSelectedUser: (updateSelectedUser: boolean) => ({ type: actionTypes.UpdateSelectedUser, payload: { updateSelectedUser } }),
}
